/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState } from "react";
import WishSimpleCard from "./WishSimpleCard";
import Tree from "./WishTree/Tree";
import TreeNode from "./WishTree/TreeNode";
import data from "../data/Data.json";
import { useEffect } from "react";
import WishToaster from "./WishToaster";
import { AppUtils } from "../services/AppUtils";
import Popover from "./Popover";
import NodeDetails from "./WishTree/NodeDetails";
import ReactPopover from "./ReactPopover";

export default function WishGeneologyTree({
  tree,
  reverse,
  header,
  footer,
  onNodeSelected,
  onOrganizationSelected,
  organizations,
  hideExitingEnrollments,
  onResetRequested,
  showBackButton,
  onSearchClicked,
  loading,
}) {
  // const [isRotated, setIsRotated] = useState(
  //   reverse !== undefined ? reverse : false
  // );

  const [isRootNode, setIsRootNode] = useState(true);
  const [selectedNode, setSelectedNode] = useState(tree);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [treeNodes, setTreeNodes] = useState(tree.nodes);

  useEffect(() => {
    //setIsRotated(reverse);
    setSelectedNode(tree);
    setTreeNodes(tree.nodes);
    AppUtils.showDialog("dlgEnrollUser");
  }, [tree]);

  const clearNodeSelection = function () {
    const treeNodesCopy = Array.from(treeNodes);
    treeNodesCopy.forEach(function (treenode, index) {
      treenode.selected = false;

      treenode.nodes.forEach((node, index) => {
        node.selected = false;
      });
    });

    setSelectedNode(tree);
    setTreeNodes(treeNodesCopy);
    onNodeSelected && onNodeSelected(null);
  };

  const getDistributor = function (id, changeSelection) {
    const treeNodesCopy = Array.from(treeNodes);
    treeNodesCopy.forEach(function (treenode, index) {
      treenode.selected = false;
      if (treenode.id === id) {
        treenode.selected = changeSelection;

        if (changeSelection) {
          setSelectedNode(treenode);
          onNodeSelected && onNodeSelected(treenode);
        } else {
          return treenode;
        }
      }

      treenode.nodes.forEach((node, index) => {
        node.selected = false;
        if (node.id === id) {
          node.selected = changeSelection;

          if (changeSelection) {
            setSelectedNode(node);
            onNodeSelected && onNodeSelected(node);
          } else {
            return node;
          }
        }
      });
    });

    if (changeSelection) {
      setIsRootNode(false);
      setTreeNodes(treeNodesCopy);
    }
  };

  const onClicked = function (id, isActionNode, clickedNode) {
    //getDistributor(id, true);
    onNodeSelected && onNodeSelected(id, isActionNode, clickedNode);
  };

  const renderTreeNode = function ({ node, isThisRootNode = false, isSelectedNode = false }) {
    return (
      <div className="text-center">
        <img
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleNodeClick(node);
          }}
          className="shadow-sm"
          src={"../assets/app-assets/images/badges/" + node?.rankBadge}
          alt="not found"
        />
        <div>
          <small
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleNodeClick(node);
            }}
          >
            {node?.distId}
          </small>{" "}
          {!node?.isActionNode ? (
            node?.activityStatus === 1 ? (
              <span className="dot-success"></span>
            ) : node?.activityStatus === 2 ? (
              <span className="dot-danger"></span>
            ) : (
              <span className="dot-disabled"></span>
            )
          ) : (
            <span className="dot-empty"></span>
          )}
        </div>
        <div className="genealogy-name-wrapper">
          <a
            className="text-primary clickable genealogy-name"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleNodeClick(node);
            }}
          >
            {AppUtils.toSentenceCase(node?.name)}
          </a>
          {!node?.isActionNode && <ReactPopover triggerText="i" content={<NodeDetails details={node} />} className="question-mark" />}
        </div>
        {node.isMobileAppDownloaded ? <span className="badge badge-primary text-extra-small">App User</span> : null}
      </div>
    );
  };

  const renderAddNode = function ({ node, isThisRootNode = false, isSelectedNode = false }) {
    return (
      <div className="text-center">
        <div className="">
          <i className="las la-plus"></i>
        </div>
        <div>
          <small>{node.distributorID}</small> {node.status.toLowerCase() === "active" ? <span className="dot-success"></span> : <span className="dot-disabled"></span>}
        </div>
        <div>{node.title}</div>
      </div>
    );
  };

  const renderStatsDialogContent = function () {
    const ranks = data.timelineData;
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th style={{ minWidth: "50%" }}></th>
              <th>Organization 1</th>
              <th>Organization 2</th>
            </tr>
          </thead>
          <tbody>
            {ranks &&
              ranks.map((rank, index) => {
                return (
                  <tr key={index}>
                    <td>{rank.title}</td>
                    <td className="text-center">{rank.completed && Math.round(Math.random() * 10)}</td>
                    <td className="text-center">{rank.completed && Math.round(Math.random() * 10)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleNodeClick = (clickedNode) => {
    if (clickedNode.isActionNode) {
      if (clickedNode.isBerthEnabled) {
        onClicked(clickedNode?.parentDistId, clickedNode?.isActionNode, clickedNode);
      } else {
        WishToaster({ toastMessage: "Cannot add member in this position." });
      }
    } else {
      onClicked(clickedNode?.distId, clickedNode?.isActionNode, clickedNode);
    }
  };

  return (
    <>
      <WishSimpleCard
        //header={header ?? null}
        className="rounded-1"
        changeBorder={false}
        body={
          <>
            {header ?? null}
            <div style={{ maxWidth: "100%", overflowX: "scroll", background: "white" }}>
              <div
                // className={
                //   isRotated === false ? " wish-rotate-0 " : " wish-rotate-180 "
                // }
                style={{ width: "max-content", minWidth: "100%" }}
              >
                <Tree
                  reverse={reverse}
                  displayBackButton={showBackButton}
                  label={renderTreeNode({
                    node: tree,
                    isThisRootNode: true,
                    isSelectedNode: true,
                  })}
                  lineWidth={"2px"}
                  onSearchClick={(filterText) => {
                    onSearchClicked && onSearchClicked(filterText);
                  }}
                  onBackButtonClick={() => {
                    onResetRequested && onResetRequested();
                  }}
                >
                  {treeNodes.map((treenode, index) => {
                    return (
                      <TreeNode
                        label={renderTreeNode({
                          node: treenode,
                          isSelectedNode: treenode?.selected,
                        })}
                        id={treenode.id}
                        key={index}
                        selected={treenode.selected && !treenode.isActionNode}
                        onClick={(e) => {
                          handleNodeClick(treenode);
                        }}
                        details={treenode}
                        hide={treenode.hide}
                      >
                        {treenode.nodes &&
                          treenode.nodes.map((node, nIndex) => {
                            return (
                              <TreeNode
                                label={renderTreeNode({
                                  node: node,
                                  isSelectedNode: node.selected,
                                })}
                                id={node.id}
                                key={nIndex}
                                selected={node.selected}
                                onClick={(e) => {
                                  handleNodeClick(node);
                                }}
                                details={node}
                                hide={node.hide}
                              ></TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                </Tree>
              </div>
            </div>
          </>
        }
        footer={footer ?? null}
      ></WishSimpleCard>
    </>
  );
}
