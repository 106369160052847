import React, { useState, useEffect, useRef } from "react";

function NumberCounter({ targetValue, justValue = false }) {
  const [currentValue, setCurrentValue] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const currentValueRef = useRef(currentValue);

  useEffect(() => {
    setIsAnimating(true);
    const startTime = Date.now();
    const duration = 2000; // You can adjust the duration as needed
    const startValue = currentValueRef.current;

    const animate = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime >= duration) {
        setCurrentValue(targetValue);
        currentValueRef.current = targetValue; // Update the ref
        setIsAnimating(false);
      } else {
        // Custom easing function that starts fast and slows down
        const easingFactor = 1 - Math.pow(1 - elapsedTime / duration, 2);
        const newValue = startValue + (targetValue - startValue) * easingFactor;
        setCurrentValue(newValue);
        requestAnimationFrame(animate);
      }
    };

    animate();
  }, [targetValue]);

  return justValue ? (
    currentValue
  ) : (
    // <div className={`number-counter ${isAnimating ? "animating" : ""}`}>
      <span>{Math.round(currentValue)}</span>
    // </div>
  );
}

export default NumberCounter;
