import React from "react";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import { AUTHENTICATION_MODES } from "../../services/Constants";
import WishFlexBox from "../../components/WishFlexBox";

const ForgotPasswordForm = ({ credentials, errorMessage, isLoading, DoChangePassword, setMode, setCredentials, setErrorMessage }) => {
  return (
    <div className="card-body">
      <p className="lead">Enter Direct Seller ID to reset the password</p>
      <WishSingleLineText
        placeholder="Direct Seller Id"
        icon="las la-user"
        initialValue={credentials.user_name}
        onChange={(value) => {
          setCredentials({ ...credentials, user_name: value });
        }}
      />
      <div className="form-group text-center">
        <p className="text-danger">{errorMessage}</p>
      </div>
      <div className="form-group text-center">
        <button
          type="button"
          disabled={isLoading}
          onClick={() => {
            DoChangePassword();
          }}
          className={"btn btn-block text-uppercase " + (isLoading ? " btn-secondary" : " btn-success ")}
        >
          {isLoading ? (
            <WishFlexBox justifyContent="center">
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <strong>&nbsp; Processing</strong>
            </WishFlexBox>
          ) : (
            <>Request for OTP </>
          )}
        </button>
      </div>
      <div className="text-center">
        <a
          className="card-link link-dotted text-primary"
          onClick={() => {
            setErrorMessage("");
            setCredentials({ user_name: "", password: "" });
            setMode(AUTHENTICATION_MODES.sign);
          }}
        >
          Back to Sign In
        </a>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
