import React, { useState } from "react";
import { useEffect } from "react";

const Pagination = ({ pageno = 1, totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(pageno);

  useEffect(() => {
    setCurrentPage(pageno);
  }, [pageno]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    // Add "Previous" button
    if (currentPage > 1) {
      paginationItems.push(
        <li key="previous" className="page-item">
          <button className="page-link" key="previous" onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </button>
        </li>
      );
    }

    // Add first page button
    paginationItems.push(
      <li key="first" className={currentPage === 1 ? "active page-item" : "page-item"}>
        <button key={1} onClick={() => handlePageChange(1)} className="page-link">
          1
        </button>
      </li>
    );

    // Add page number buttons
    if (totalPages <= 10) {
      // Case 1: Total pages less than or equal to 10
      for (let page = 2; page < totalPages; page++) {
        paginationItems.push(
          <li key={page} className={currentPage === page ? "active page-item" : "page-item"} aria-current="page">
            <button key={page} onClick={() => handlePageChange(page)} className="page-link">
              {page}
            </button>
          </li>
        );
      }
    } else if (currentPage <= 4) {
      // Case 2: Current page is close to the beginning
      for (let page = 2; page <= 4; page++) {
        paginationItems.push(
          <li key={page} className={currentPage === page ? "active page-item" : "page-item"} aria-current="page">
            <button key={page} onClick={() => handlePageChange(page)} className={"page-link"}>
              {page}
            </button>
          </li>
        );
      }
      paginationItems.push(<span key="ellipsis">...</span>);
    } else if (currentPage >= totalPages - 4) {
      // Case 3: Current page is close to the end
      paginationItems.push(<span key="ellipsis">...</span>);
      for (let page = totalPages - 4; page < totalPages; page++) {
        paginationItems.push(
          <li key={page} className={currentPage === page ? "active page-item" : "page-item"} aria-current="page">
            <button key={page} onClick={() => handlePageChange(page)} className={"page-link"}>
              {page}
            </button>
          </li>
        );
      }
    } else {
      // Case 4: Current page is in the middle
      paginationItems.push(<span key="ellipsis1">...</span>);
      for (let page = currentPage - 2; page <= currentPage + 2; page++) {
        paginationItems.push(
          <li key={page} className={currentPage === page ? "active page-item" : "page-item"} aria-current="page">
            <button key={page} onClick={() => handlePageChange(page)} className={currentPage === page ? "active page-link" : "page-link"}>
              {page}
            </button>
          </li>
        );
      }
      paginationItems.push(<span key="ellipsis2">...</span>);
    }

    // Add last page button
    if (totalPages > 1) {
      paginationItems.push(
        <li key={totalPages} className={currentPage === totalPages ? "active page-item" : "page-item"} aria-current="page">
          <button key={totalPages} onClick={() => handlePageChange(totalPages)} className={currentPage === totalPages ? "active page-link" : "page-link"}>
            {totalPages}
          </button>
        </li>
      );
    }

    // Add "Next" button
    if (currentPage < totalPages) {
      paginationItems.push(
        <button className="page-link" key="next" onClick={() => handlePageChange(currentPage + 1)}>
          Next
        </button>
      );
    }

    return paginationItems;
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-end">{totalPages ? renderPaginationItems() : null}</ul>
    </nav>
  );
};

export default Pagination;
