// import { useNavigate } from "react-router-dom";
import EmptyBox from "../../../components/EmptyBox";
import WishSimpleCard from "../../../components/WishSimpleCard";
// import WishToaster from "../../../components/WishToaster";
// import CommonAPIs from "../../../services/commonAPIs";

export const DistProfileBox = ({ distributorDetails, activityDetails }) => {
  // const navigate = useNavigate();
  // const { CheckEligStatus } = CommonAPIs();

  return (
    <WishSimpleCard>
      {distributorDetails?.distributor_id ? (
        <div className="text-center">
          <img src={`../../assets/app-assets/images/badges/${distributorDetails?.distributor_rank?.rank_title}.png`} alt="" style={{ width: "50px" }} />
          <EmptyBox height="10px" />
          <p style={{ lineHeight: "1.0" }}>
            <label className="d-block">{distributorDetails?.distributor_id}</label>
            <h4>{distributorDetails?.name}</h4>
            <small>{distributorDetails?.distributor_rank?.rank_title}</small>
            {activityDetails.comment && (
              <div className="status-text mt-1">
                <label className="font-weight-bold text-danger text-center mb-0 mr-1">{activityDetails.comment}</label>
                {/* <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    CheckEligStatus(
                      { distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id },
                      (data) => {
                        if (data) {
                          navigate("/placeorder", { state: { distId: JSON.parse(localStorage.getItem("distributor"))?.distributor_id, pvData: data } });
                        }
                      },
                      (error) => {
                        WishToaster({ toastTitle: "Error", toastType: "error", toastMessage: "Error fetching your PV details" });
                      }
                    );
                  }}
                >
                  Order Now
                </button> */}
              </div>
            )}
          </p>
        </div>
      ) : (
        <>
          <div className="skeleton-image"></div>
          <div className="skeleton-text">
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </div>
        </>
      )}
    </WishSimpleCard>
  );
};
