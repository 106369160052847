import React, { useEffect } from "react";
import data from "../../data/Data.json";

export default function NodeDetails({ details }) {
  const ranks = JSON.parse(localStorage.getItem("ranks"));
  const distributor = JSON.parse(localStorage.getItem("distributor"));
  const getStatusTitle = (id) => {
    const status = data.activityStatuses.find((item) => item.activity_status_id === id);
    return status ? status.activity_status_title : "Unknown"; // Default to 'Unknown' if ID doesn't match
  };

  const getRankTitle = (id) => {
    const rank = ranks?.find((item) => item.id === id);
    return rank ? rank.title : "Unknown"; // Default to 'Unknown' if ID doesn't match
  };

  return (
    <table border="1" width="100%" id="genealogy-hover-table" className="table table-borderless m-0">
      <tbody>
        <tr>
          <td colSpan="6">
            <h6>
              {details?.name} [{details?.distId}]&nbsp;&nbsp;
            </h6>
            Status: {getStatusTitle(details?.activityStatus)}
          </td>
        </tr>
        <tr>
          <td colSpan="6">Achieved Rank: {getRankTitle(details?.achievedRankId)}</td>
        </tr>
        <tr>
          <td colSpan="6">Next Activation week: {details?.resetWeekId}</td>
        </tr>
        <tr>
          <td colSpan="6">{details?.isMobileAppDownloaded ? "Mobile App Downloaded" : "Mobile App Not Downloaded"}</td>
        </tr>
        <tr>
          <td colSpan="6">{details?.is_quiz_attended ? `Tour de Thai quiz attended - Score ${details?.quiz_score}` : "Yet to take quiz"}</td>
        </tr>
        <tr>
          <td colSpan="3">
            <strong>CF1: {details?.cfl}</strong>
          </td>
          <td colSpan="3">
            <strong>CF2: {details?.cfr}</strong>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
            <strong>Previous week GV</strong>
          </td>
          <td colSpan="3">
            <strong>Current week GV</strong>
          </td>
        </tr>
        {data.organizations.slice(0, distributor.distributor_id === details?.distId ? data.organizations.length : 2).map((org, index) => (
          <tr key={org}>
            <td colSpan={"2"}>{org}</td>
            <td>{details?.pwGv[index]}</td>
            <td colSpan={"2"}>{org}</td>
            <td>{details?.gv[index]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
