import React, { useState } from "react";
import { useEffect } from "react";
import PageLayout from "../components/PageLayout";
import AnnouncementItem from "../components/AnnouncementItem";
import useAnnouncement from "../services/useAnnouncement";
import Pagination from "../components/Pagination";
import EmptyAnimation from "../components/EmptyAnimation";

export default function Announcements() {
  const distFromLocal = localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor")) : null;
  const [loading, setLoading] = useState(true);
  const { fetchAnnouncements } = useAnnouncement();
  const [announcements, setAnnouncements] = useState([]);
  const [error, setError] = useState("");
  const [payload, setPayload] = useState({
    page_no: 1,
    total_no_of_pages: 0,
    distributor_id: distFromLocal?.distributor_id,
    dist_rank_id: distFromLocal?.distributor_rank?.rank_id,
  });

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "News Feed", linkTo: "/announcements" });

  useEffect(() => {
    getLists();
  }, []);

  const getLists = () => {
    setLoading(true);
    fetchAnnouncements(
      payload,
      (data, response) => {
        setAnnouncements(data);
        payload.total_no_of_pages = data?.total_no_of_pages;
        setLoading(false);
        if (data?.length <= 0) setError(response.message);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <PageLayout activeSideMenu="7" header="News Feed" breadcrumbs={breadcrumbs}>
      <section className="row">
        <div className="col-12">
          {loading ? (
            <>
              <div className="card is-loading">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="image"></div>
                  </div>
                </div>
              </div>
              <div className="card is-loading">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="image"></div>
                  </div>
                </div>
              </div>
              <div className="card is-loading">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="image"></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {announcements?.announcements ? (
                announcements?.announcements?.map((item, index) => (
                  <div className="card  onhover-change-border false box-shadow-0 onhover-shadow" style={{ height: "auto" }} key={index}>
                    <div className="card-body false">
                      <AnnouncementItem title={item.title} url={item.announcement_file_url} time={item.valid_from} item={item}>
                        <pre>{item.body}</pre>
                      </AnnouncementItem>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyAnimation message={error && "No mails found"} />
              )}
              <Pagination
                pageno={payload?.page_no}
                onPageChange={(page) => {
                  payload.page_no = page;
                  getLists();
                }}
                totalPages={payload?.total_no_of_pages}
              />
            </>
          )}
        </div>
      </section>
    </PageLayout>
  );
}
