/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { css, cx } from "@emotion/css";
import { v4 as uuidv4 } from "uuid";
import "./tree.css";

const verticalLine = css`
  content: "";
  position: absolute;
  top: 0;
  height: var(--tree-line-height);
  box-sizing: border-box;
  z-index: -1;
`;
const childrenContainer = css`
  display: flex;
  padding-inline-start: 0;
  margin: 0;
  padding-top: var(--tree-line-height);
  position: relative;

  ::before {
    ${verticalLine};
    left: 50%;
    width: 0;
    border-left: var(--tree-line-width) solid var(--tree-line-color);
    z-index: -1;
  }
`;
const node = css`
  flex: auto;
  text-align: center;
  list-style-type: none;
  position: relative;
  line-height: 1.2 !important;
  padding: var(--tree-line-height) var(--tree-node-padding) 0 var(--tree-node-padding);
`;
const nodeLines = css`
  ::before,
  ::after {
    ${verticalLine};
    right: 50%;
    width: 50%;
    border-top: var(--tree-line-width) solid var(--tree-line-color);
    z-index: -1;
  }
  ::after {
    left: 50%;
    border-left: var(--tree-line-width) solid var(--tree-line-color);
    z-index: -1;
  }

  :only-of-type {
    padding: 0;
    ::after,
    :before {
      display: none;
    }
  }

  :first-of-type {
    ::before {
      border: 0 none;
    }
    ::after {
      border-radius: var(--tree-line-border-radius) 0 0 0;
    }
  }

  :last-of-type {
    ::before {
      border-right: var(--tree-line-width) solid var(--tree-line-color);
      z-index: -1;
      border-radius: 0 var(--tree-line-border-radius) 0 0;
    }
    ::after {
      border: 0 none;
    }
  }
`;

export default function TreeNode({ children, label, className, onClick, onDoubleClick, onMouseEnter, onMouseLeave, selected, isRoot, id, details, hide }) {
  const nodeID = id ?? uuidv4();

  return (
    <li className={cx(node, nodeLines, className)}>
      <div style={{ position: "relative" }} className="label-container">
        <div className="node-group">
          <label
            id={nodeID}
            onMouseEnter={() => {
              onMouseEnter && onMouseEnter(nodeID);
            }}
            onMouseLeave={() => {
              onMouseLeave && onMouseLeave(nodeID);
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
              onDoubleClick && onDoubleClick(nodeID);
            }}
            className={"hand-cursor" + (selected && selected === true ? " selected " : " treenode ") + (isRoot ? " rootNode " : "") + (hide ? " hidden " : "")}
          >
            {label}
          </label>
        </div>
      </div>
      {React.Children.count(children) > 0 && <ul className={childrenContainer}>{children}</ul>}
    </li>
  );
}
