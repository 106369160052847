import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import { AUTHENTICATION_MODES } from "../../services/Constants";
import WishFlexBox from "../../components/WishFlexBox";
import useAuthentication from "../../services/useAuthentication";

const VerifyOTP = ({ credentials, forgotPasswordResponse, setMode }) => {
  const SwalAlert = withReactContent(Swal);

  const { verifyPasswordReset } = useAuthentication();
  const [payload, setPayload] = useState({ user_name: credentials.user_name, phone_otp: "", password: "", confirm_password: "" });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const changePassword = () => {
    if (payload.password === payload.confirm_password) {
      setErrorMessage("");
      setLoading(true);
      verifyPasswordReset(
        payload,
        (data) => {
          setLoading(false);
          SwalAlert.fire({
            icon: "success",
            title: "Success!",
            text: "Your password has been changed successfully. Please login with your new password.",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            setMode(AUTHENTICATION_MODES.sign);
          });
        },
        (error) => {
          setErrorMessage(error);
          setLoading(false);
        }
      );
    } else {
      setErrorMessage("Passwords do not match");
    }
  };

  return (
    <div className="card-body">
      <div className="text-center">
        <h1>
          <i className="las la-phone la-2x text-warning"></i>
        </h1>
        <h3>Check your phone</h3>
        <p className="lead pb-2">Enter the OTP received to your registered Mobile number and new password.</p>
        <WishSingleLineText
          placeholder="OTP"
          icon="las la-lock"
          initialValue={payload.phone_otp}
          onChange={(value) => {
            setPayload({ ...payload, phone_otp: value });
          }}
        />
        <WishSingleLineText
          placeholder="New Password"
          icon="las la-lock"
          initialValue={payload.password}
          onChange={(value) => {
            setPayload({ ...payload, password: value });
          }}
        />
        <WishSingleLineText
          placeholder="Confirm Password"
          icon="las la-lock"
          initialValue={payload.confirm_password}
          onChange={(value) => {
            setPayload({ ...payload, confirm_password: value });
          }}
        />
        <div className="form-group text-center">
          <p className="text-danger">{errorMessage}</p>
        </div>
        <div className="form-group text-center">
          <button
            type="button"
            disabled={loading}
            onClick={() => {
              changePassword();
            }}
            className={"btn btn-block text-uppercase " + (loading ? " btn-secondary" : " btn-success ")}
          >
            {loading ? (
              <WishFlexBox justifyContent="center">
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <strong>&nbsp; Processing</strong>
              </WishFlexBox>
            ) : (
              <>Change Password</>
            )}
          </button>
        </div>
        <div className="text-center">
          <a
            className="card-link link-dotted text-primary"
            onClick={() => {
              setErrorMessage("");
              setMode(AUTHENTICATION_MODES.forgotPassword);
            }}
          >
            Go Back
          </a>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
