import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/app-assets/images/lottie/empty.json";

export default function EmptyAnimation({ message, lottieAnimation }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation ? lottieAnimation : animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Lottie options={defaultOptions} height={250} width={250} />
      <div style={{ marginLeft: "16px" }}>
        <h5 style={{ marginBottom: "8px", textAlign: "center" }}>{message ?? "No data found"}</h5>
      </div>
    </div>
  );
}
