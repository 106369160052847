import { useState } from "react";
import { useEffect } from "react";
import useLocalStorage from "react-use-localstorage";
import useAPIs from "./useAPIs";

const useMasters = () => {
  const [ranks, setRanks] = useLocalStorage("ranks", null);
  const [ranksList, setRanksList] = useState(null);
  const [mastersError, setError] = useState(null);
  const { apiError, postData, getData } = useAPIs();

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  const [distributor, setDistributor] = useLocalStorage("distributor", null);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    if (distributor && distributor !== "") {
      const distributorFromLocalStorage = JSON.parse(distributor);
      setLoggedInUser(distributorFromLocalStorage);
    }

    if (ranks && ranks !== "") {
      const ranksFromLocalStorage = JSON.parse(ranks);

      setRanksList(ranksFromLocalStorage);
    } else {
      getData("/enrollment/fetch-rank-list", (ranksData) => {
        setRanksList(ranksData);
        setRanks(JSON.stringify(ranksData));
      });
    }
  }, []);

  const updateDistributor = (distributorDetails) => {
    if (distributorDetails) {
      setDistributor(JSON.stringify(distributorDetails));
      setLoggedInUser(distributorDetails);
    }
  };

  const getNotifications = (payload, onSuccess, onError) => {
    payload.distributor_id = loggedInUser?.distributor_id;
    postData(
      "/notif/fetch-dist-notification",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const updateNotificationStatus = (payload, onSuccess, onError) => {
    payload.distributor_id = loggedInUser?.distributor_id;
    payload.platform_id = 1;
    postData(
      "/notif/update_message_status",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  return {
    ranksList,
    mastersError,
    loggedInUser,
    updateDistributor,
    getNotifications,
    updateNotificationStatus,
  };
};

export default useMasters;
