import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "animate.css";
import MaintenanceMode from "./views/MaintenanceMode";
import NoNetworkFallbackUI from "./views/NoNetworkFallback";
import RoutesComponent from "./routes";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine); // to check network status
  const [maintenanceMode, setMaintenanceMode] = useState(false); // Set this to true when maintenance is required

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  // onMessage(messaging, (payload) => {
  //   console.log("Message received. ", payload);
  //   toast(
  //     (t) => (
  //       <span className="d-flex flex-direction-column justify-content-center">
  //         <b className="text-center" style={{ marginBottom: "0.5rem" }}>
  //           {payload?.notification?.title}
  //         </b>
  //         <span className="text-center text-small">{payload?.notification?.body}</span>
  //         <button className="btn btn-sm btn-link" onClick={() => toast.dismiss(t.id)}>
  //           Dismiss
  //         </button>
  //       </span>
  //     ),
  //     {
  //       duration: 66000,
  //     }
  //   );
  // });

  useEffect(() => {
    const disableRightClick = (event) => {
      event.preventDefault();
    };
    const isTouchDevice = "ontouchstart" in document.documentElement;

    if (!isTouchDevice) {
      // Attach the event listener only for non-touch devices
      document.addEventListener("contextmenu", disableRightClick);
    }

    // Cleanup event listener when the component unmounts
    return () => {
      if (!isTouchDevice) {
        document.removeEventListener("contextmenu", disableRightClick);
      }
    };
  }, []); // Empty dependency array ensures that the effect runs only once when the component mounts

  // async function requestPermission() {
  //   //requesting permission using Notification API
  //   const permission = await Notification.requestPermission();
  //   if (permission === "granted") {
  //     localStorage.setItem("notificationPermission", true);
  //     const token = await getToken(messaging, {
  //       vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  //     });
  //     //We can send token to server
  //     console.log("Token generated : ", token);
  //   } else if (permission === "denied") {
  //     //notifications are blocked
  //     localStorage.setItem("notificationPermission", false);
  //   }
  // }

  return maintenanceMode ? (
    <MaintenanceMode />
  ) : (
    <>
      {isOnline ? (
        <Router>
          <RoutesComponent />
        </Router>
      ) : (
        <NoNetworkFallbackUI />
      )}
      <Toaster />
    </>
  );
};

export default App;
