import WishSimpleCard from "./WishSimpleCard";

const GenealogyLoader = ({ message }) => {
  return (
    <WishSimpleCard noFooter>
      <div className="card is-loading">
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="image"></div>
          </div>
          <div className="col-sm-12">
            <div className="content-1">
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </WishSimpleCard>
  );
};

export default GenealogyLoader;
