import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Invoice.css";
import Logo from "../assets/app-assets/images/logo/LOGO-01.png";
import { AppUtils } from "../services/AppUtils";

const CreditNote = () => {
  const location = useLocation();

  const [invoices, setInvoices] = useState(null);

  useEffect(() => {
    setInvoices(location.state.data);
  }, []);

  return (
    <React.Fragment>
      {invoices &&
        invoices.map((data) => (
          <div style={{ pageBreakBefore: "always" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px" }}>
              <img style={{ width: "180px", padding: "15px 15px", float: "left" }} src={Logo} alt="" />
              <h1 style={{ textAlign: "center" }}>Tax Invoice</h1>
              <img src={data?.qrcode} alt="QR Code" style={{ width: "100px", height: "100px" }} />
            </div>
            <table className="invoice-template-table">
              <tbody>
                <tr>
                  <th rowSpan="2" colSpan="2">
                    <div>
                      Indusviva Healthsciences Pvt Ltd <br />
                      {data?.warehouse?.address_line1} <br />
                      {data?.warehouse?.address_line2} -{data?.warehouse?.postalcode}. <br />
                      <strong>GSTIN/UIN : </strong>
                      {data?.warehouse?.gstin}
                      <br />
                      <strong>State Name : </strong>
                      {data?.warehouse?.state_name} <br />
                      <strong>Code : </strong>
                      {data?.warehouse?.gstin?.substring(0, 2)}
                      <br />
                      <strong>CIN : </strong>
                      {data?.warehouse?.cin} <br />
                      <strong>Phone : </strong>
                      1800-103-4916
                      <br />
                      <strong>Website : </strong>www.indusviva.com
                    </div>
                  </th>
                  <th colSpan="3">
                    <strong>Credit Note No: </strong> <br />
                    <span className="table-invoice-no">{data?.credit_note_no}</span>
                  </th>
                  <th colSpan="2">
                    <strong>Credit Note Date: </strong> <br />
                    {AppUtils.formatDateTime(data?.credit_note_date)}
                  </th>
                  <td colspan="6" rowspan="2">
                    Dispatch through : <strong>{data?.courier_name}</strong>
                    <br />
                    Dispatch Doc no : <strong>{data?.docket_no}</strong>
                    <br />
                    Dispatch Date : <strong>{AppUtils.formatDateTime(data?.dispatched_on)}</strong>
                    <br />
                    Destination : <strong>{data?.destination}</strong>
                  </td>
                </tr>
                <tr>
                  <th colSpan="3">
                    <strong>Mode of payment</strong> <br />
                    {data?.modeofpay} <br />
                    <strong>Bank Generated Number: </strong> <br />
                    {data?.bank_ref_no}
                    <br />
                    <strong>Payment Gateway Reference No: </strong> <br />
                    {data?.provider_ref_no} <br />
                  </th>
                  <th colSpan="2">
                    <strong>Order No:</strong> <br />
                    {data?.order_no}
                  </th>
                </tr>
                <tr>
                  <td colSpan="5">
                    <strong> Consignee (Ship To)</strong> <br />
                    <br />
                    Name : <strong>{data?.ship_to?.name}</strong>
                    <br />
                    Address :{" "}
                    <strong>
                      {data?.ship_to?.address_line1}, {data?.ship_to?.address_line2}, {data?.ship_to?.city_name}
                    </strong>
                    <br />
                    State Name : <strong>{data?.ship_to?.state_name}</strong> <br />
                    Postalcode :<strong>{data?.ship_to?.postalcode}</strong>
                    <br />
                    Phone : <strong>{data?.ship_to?.phone}</strong>
                    <br />
                  </td>
                  <td colSpan="4">
                    <strong>Buyer (Bill TO)</strong>
                    <br />
                    <br />
                    Name : {data?.distributor_name}
                    <br />
                    Address :{data?.address?.address_line1}, {data?.address?.address_line2}, {data?.address?.city_name},
                    <br />
                    State Name : {data?.address?.state_name} <br />
                    Postalcode :{data?.address?.postalcode}
                    <br />
                    Phone : {data?.address?.phone}
                    <br />
                    GSTIN : {data?.distributor_gst_no}
                    <br />
                    Direct Seller ID : <strong>{data?.distributor_id}</strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan="1">SL NO</td>
                  <td colSpan="1">Description</td>
                  <td className="text-center" colSpan="1">
                    HSN / SAC
                  </td>
                  <td className="text-center" colSpan="1">
                    GST Rate
                  </td>
                  <td className="text-center" colSpan="1">
                    Quantity
                  </td>
                  <td className="text-center" colSpan="1">
                    UOM
                  </td>
                  <td className="text-center" colSpan="1">
                    Unit Price
                  </td>
                  <td className="text-center" colSpan="1">
                    Discount
                  </td>
                  <td className="text-center" colSpan="1">
                    Amount
                  </td>
                </tr>
                {data?.order_items &&
                  data?.order_items?.map((product) => (
                    <tr>
                      <td>{product?.serialnumber}</td>
                      <td className="text-bold">{product?.product_name}</td>
                      <td className="text-center">{product?.hsncode}</td>
                      <td className="text-center">{product?.tax_percentage}%</td>
                      <td className="text-center text-bold">{product?.quantity}</td>
                      <td className="text-center">NOS</td>
                      <td className="text-right">{Number(product?.item_total_price).toFixed(2)}</td>
                      <td className="text-right">{Number(product?.item_total_discount).toFixed(2)}</td>
                      <td className="text-right">{Number(product?.item_total_price).toFixed(2)}</td>
                    </tr>
                  ))}
                {data?.voucher_items &&
                  data?.voucher_items?.map((product) => (
                    <tr>
                      <td>{product?.serialnumber}</td>
                      <td className="text-bold">{product?.product_name}</td>
                      <td className="text-center">{product?.hsncode}</td>
                      <td className="text-center">{product?.tax_percentage}%</td>
                      <td className="text-center text-bold">{product?.quantity}</td>
                      <td className="text-center">NOS</td>
                      <td className="text-right">{Number(product?.item_total_price).toFixed(2)}</td>
                      <td className="text-right">{Number(product?.item_total_discount).toFixed(2)}</td>
                      <td className="text-right">{Number(product?.item_total_price).toFixed(2)}</td>
                    </tr>
                  ))}
                <tr>
                  <td></td>
                  <td>Handling Charges</td>
                  <td className="text-center">996719</td>
                  <td className="text-center">18%</td>
                  <td></td>
                  <td></td>
                  <td className="text-right">{Number(data?.total_base_handling_charge).toFixed(2)}</td>
                  <td className="text-right">NIL</td>
                  <td className="text-right">{Number(data?.total_base_handling_charge).toFixed(2)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={"2"}>
                    Total Before Tax &amp; Discount
                    <br />
                    {data?.tax_method} TAX Round Of
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-right">
                    {Number(data?.total_price).toFixed(2)}
                    <br />
                    {Number(data?.total_tax).toFixed(2)}
                    <br />
                  </td>
                </tr>
                <tr>
                  <td colSpan="8">Total Amount</td>
                  <td className="text-right text-bold">{Number(data?.total_amount).toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="9">
                    Amount in Words : <strong style={{ textTransform: "capitalize" }}> {data?.total_amount_in_words} only</strong>
                  </td>
                </tr>
                <tr>
                  <td rowSpan="2">
                    <strong>HSN / SAC</strong>
                  </td>
                  <td rowSpan="2">
                    <strong>Taxable Value</strong>
                  </td>
                  <td className="text-center" colSpan="2">
                    <strong>Central Tax</strong>
                  </td>
                  <td className="text-center" colSpan="2">
                    <strong>State Tax</strong>
                  </td>
                  <td className="text-center" colSpan="2">
                    <strong>Interated Tax</strong>
                  </td>
                  <td className="text-center" rowSpan="2">
                    <strong>Total Tax Amount</strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">Rate</td>
                  <td className="text-center">Amount</td>
                  <td className="text-center">Rate</td>
                  <td className="text-center">Amount</td>
                  <td className="text-center">Rate</td>
                  <td className="text-center">Amount</td>
                </tr>
                {data?.order_items &&
                  data?.order_items?.map((product) => (
                    <tr>
                      <td>{product?.hsncode}</td>
                      <td>{Number(product?.item_total_price).toFixed(2)}</td>
                      <td className="text-center">{product?.tax_percentage} %</td>
                      <td className="text-right">{product.item_total_tax_cgst}</td>
                      <td className="text-center">{product?.tax_percentage} %</td>
                      <td className="text-right">{product.item_total_tax_sgst}</td>
                      <td className="text-center">{product?.tax_percentage} %</td>
                      <td className="text-right">{product.item_total_tax_igst}</td>
                      <td className="text-right">{Number(product?.item_total_tax).toFixed(2)}</td>
                    </tr>
                  ))}
                <tr>
                  <td>996719</td>
                  <td>{Number(data?.total_base_handling_charge).toFixed(2)}</td>
                  <td className="text-center">{data?.total_handling_charge_cgst_percentage} %</td>
                  <td className="text-right">{data.total_handling_charge_cgst}</td>
                  <td className="text-center">{data?.total_handling_charge_sgst_percentage} %</td>
                  <td className="text-right">{data.total_handling_charge_sgst}</td>
                  <td className="text-center">{data?.total_handling_charge_igst_percentage} %</td>
                  <td className="text-right">{data.total_handling_charge_igst}</td>
                  <td className="text-right">{data?.total_handling_charge_tax}</td>
                </tr>
                {/* row15 */}
                <tr>
                  <td>Total</td>
                  <td>{Number(data?.total_price).toFixed(2)}</td>
                  <td colSpan="2" className="text-right">
                    {data?.total_cgst}
                  </td>
                  <td colSpan="2" className="text-right">
                    {data?.total_sgst}
                  </td>
                  <td colSpan="2" className="text-right">
                    {data?.total_igst}
                  </td>
                  <td className="text-right">{Number(data?.total_tax).toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan="9">
                    Tax Amount (in words) :<strong style={{ textTransform: "capitalize" }}>{data?.total_tax_in_words} Only</strong>
                  </td>
                </tr>
                <tr>
                  <th colspan="9" style={{ textAlign: "right" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flexWrap: "wrap", alignContent: "flex-end" }}>
                      <span>For IndusViva HealthSciences Private Limited,</span>
                      <img src={data?.warehouse?.signature_url} alt="" style={{ width: "160px", padding: "15px" }} />
                      <span>Authorized Signatory</span>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th colSpan="9" style={{ textAlign: "center", fontSize: "11px" }}>
                    Subject to {data?.warehouse?.jurisdiction} jurisdiction <br />
                    Computer Generated Invoice, Does Not Require Signature.
                    <br />
                    Difference if any should be reported within 24 hours from receipt of this Tax Invoice.
                    <br />
                    {data?.warehouse?.address_line1}, {data?.warehouse?.address_line2}, {data?.warehouse?.city_name},{data?.warehouse?.state_name}-{data?.warehouse?.postalcode}
                    <br />
                  </th>
                </tr>
              </tbody>
            </table>
            <span>
              {data?.serialnumber} of {invoices?.length}
            </span>
          </div>
        ))}
    </React.Fragment>
  );
};

export default CreditNote;
