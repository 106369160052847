export const GroupVolumeSkeleton = () => {
  return (
    <div className="col-sm-12">
      <div className="card is-loading">
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="content-1">
              <p></p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="content-1">
              <p></p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="content-1">
              <p></p>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div className="content-1">
              <p></p>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="content-1">
              <p></p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
