import React from "react";
import WishSimpleCard from "../../components/WishSimpleCard";
import WishFlexBox from "../../components/WishFlexBox";

export default function ActivationPV({ distributorStats }) {
  return (
    <>
      <div className="col-12">
        <WishSimpleCard className="rounded-1 border-light" changeBorder={false} header={<h5>Activation PV</h5>}>
          <WishFlexBox className="row-fluid">
            {/* <label className="fs-2 col-4 pl-0 d-block">{distributorStats?.current_pv_active ?? 0} PV</label> */}
            {/* <WishFlexBox className="p-0 rounded-lg col-8 bg-danger bg-lighten-4"> */}
            <div className="col-12 rounded-lg text-center bg-danger bg-lighten-4">
              <label className="fs-2 d-block">{distributorStats?.current_pv_active ?? 0}</label>
              <label>Activation PV</label>
            </div>
            {/* <div className="col-6 text-center">
                <label className="fs-2 d-block">{distributorStats?.sponsor_activation_pv ?? 0}</label>
                <label>Activation PV from sponsoring</label>
              </div> */}
            {/* </WishFlexBox> */}
          </WishFlexBox>
          <WishFlexBox className="pt-2">
            <label className="text-info">{distributorStats?.comment ?? ""}</label>
            <label className="">Next activation week {distributorStats?.next_activation_week ?? 0}</label>
          </WishFlexBox>
        </WishSimpleCard>
      </div>
    </>
  );
}
