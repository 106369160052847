import { usePopper } from "react-popper";
import React, { useEffect, useMemo, useState } from "react";

export default function ReactPopover({ triggerText, content, className }) {
  const [referenceEl, setReferenceEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);
  const [arrowEl, setArrowEl] = useState(null);

  const hideArrowAtEdges = useMemo(
    () => ({
      name: "arrowHide",
      enabled: true,
      phase: "write",
      fn: ({ state }) => {
        const { arrow } = state.elements;

        if (arrow) {
          if (state.modifiersData.arrow.centerOffset !== 0) {
            arrowEl.setAttribute("data-hide", true);
          } else {
            arrowEl.removeAttribute("data-hide");
          }
        }
      },
    }),
    [arrowEl]
  );

  const { styles, attributes } = usePopper(referenceEl, popperEl, {
    placement: "bottom",
    modifiers: [
      { name: "arrow", options: { element: arrowEl } },
      {
        name: "flip",
        enabled: false,
      },
    ],
  });

  const toggleTooltip = () => {
    const isTooltipVisible = popperEl.getAttribute("data-show") === "true";

    if (isTooltipVisible) {
      // If tooltip is visible, hide it
      popperEl.removeAttribute("data-show");
    } else {
      // If tooltip is hidden, show it
      popperEl.setAttribute("data-show", true);
    }
  };

  const handleClickOutside = (event) => {
    if (popperEl && !popperEl.contains(event.target) && referenceEl && !referenceEl?.contains(event.target)) {
      popperEl.removeAttribute("data-show");
    }
  };

  useEffect(() => {
    const handleMouseEnter = () => {
      popperEl.setAttribute("data-show", true);
    };

    const handleMouseLeave = () => {
      popperEl.removeAttribute("data-show");
    };

    const handleTouchClick = () => {
      const isTooltipVisible = popperEl.getAttribute("data-show") === "true";
      if (isTooltipVisible) {
        popperEl.removeAttribute("data-show");
      } else {
        popperEl.setAttribute("data-show", true);
      }
    };

    // Add event listeners based on the device width
    if (window.innerWidth > 768) {
      referenceEl?.addEventListener("mouseenter", handleMouseEnter);
      referenceEl?.addEventListener("mouseleave", handleMouseLeave);
    } else {
      referenceEl?.addEventListener("click", handleTouchClick);
    }

    // Remove event listeners on component unmount
    return () => {
      referenceEl?.removeEventListener("mouseenter", handleMouseEnter);
      referenceEl?.removeEventListener("mouseleave", handleMouseLeave);
      referenceEl?.removeEventListener("click", handleTouchClick);
    };
  }, [popperEl, referenceEl]);

  return (
    <>
      <span ref={setReferenceEl} className={className}>
        {triggerText}
      </span>
      <div className="react-tooltip page-fade-in" style={styles.popper} {...attributes.popper} ref={setPopperEl}>
        {content}
        <span ref={setArrowEl} style={styles.arrow} {...attributes.arrow} className="arrow" />
      </div>
    </>
  );
}
