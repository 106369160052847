function generateTrendOptions(api, titleMain, chartType, yAxisTitle, xAxisTitle) {
  return {
    api,
    titleMain,
    chart: {
      height: "100%",
      type: chartType,
      fontFamily: "Inter, sans-serif",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: [],
      title: {
        text: xAxisTitle || "Week",
        style: {
          fontSize: "15px",
        },
      },
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: yAxisTitle || "PV",
        style: {
          fontSize: "15px",
        },
      },
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "16px",
      offsetX: 0,
      offsetY: 5,
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
}

export const FirstOrderTrendOptions = generateTrendOptions("firstOrderTrend", "First Order Trend", "line", "PV", "Week");

export const ReOrderTrendOptions = generateTrendOptions("reOrderTrend", "Re-Order Trend", "line", "PV", "Week");

export const RankTrendOptions = generateTrendOptions("rankTrend", "Rank Trend", "line", "PV", "Week");

export const VolumeTrendOptions = generateTrendOptions("volumeTrend", "Volume Trend", "line", "PV", "Week");

export const OrdersTrendOptions = {
  chart: {
    height: "100%",
    type: "line",
    fontFamily: "Inter, sans-serif",
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2,
    },
    toolbar: {
      show: true,
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      fontSize: "14px",
      fontWeight: "bold",
    },
  },
  stroke: {
    curve: "smooth",
  },
  grid: {
    show: false,
  },
  markers: {
    size: 1,
  },
  xaxis: {
    categories: [],
    title: {
      text: "Week",
      style: {
        fontSize: "12px",
      },
    },
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    title: {
      text: "No. of direct sellers",
      style: {
        fontSize: "11px",
      },
    },
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: false,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    offsetX: 0,
    offsetY: 5,
  },
  responsive: [
    {
      breakpoint: 1000,
      options: {
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
