/* eslint-disable no-undef */
export const AppUtils = {
  showDialog: function (dialogId) {
    $("#" + dialogId).modal("show");
  },

  hideDialog: function (dialogId) {
    $("#" + dialogId).modal("hide");
  },

  getFormData: function (formId) {
    var formEl = document.getElementById(formId);

    if (formEl) {
      return new FormData(formEl);
    }

    return null;
  },

  getFormDataWithFormObject: function (form) {
    //var formEl = document.getElementById(formId);

    if (form) {
      return new FormData(form);
    }

    return null;
  },

  createFormData: function (object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  },

  createURLSearchParams: function (object) {
    const encodedParams = new URLSearchParams();
    Object.keys(object).forEach((key) => encodedParams.set(key, object[key]));
    return encodedParams;
  },

  getValueFromArray: function (valueArray, matchField, matchValue, value) {
    return valueArray.filter((x) => x[matchField] === matchValue)[0][value];
  },

  formatDate: (datetime) => {
    const dateObj = new Date(datetime);

    // Convert UTC time to local time
    const localDate = new Date(dateObj.getTime());

    const today = new Date();

    if (localDate.toDateString() === today.toDateString()) {
      // Case 1: Today's date
      const time = localDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return time;
    } else if (localDate.getFullYear() === today.getFullYear()) {
      // Case 2: Within the current year
      const date = localDate.toLocaleString("en-US", { month: "short", day: "numeric" });
      return date;
    } else {
      // Case 3: Previous year
      const formattedDate = localDate.toLocaleString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      return formattedDate;
    }
  },
  formatDateTime: (datetime) => {
    if (!datetime) {
      return "No Date Available"; // Fallback if null or no data is received
    }
    const dateObj = new Date(datetime);

    if (isNaN(dateObj.getTime())) {
      return "Invalid Date"; // Fallback for invalid date format
    }

    // Convert UTC time to local time
    const localDate = new Date(dateObj.getTime());

    // Get the components for date and time
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");
    const seconds = String(localDate.getSeconds()).padStart(2, "0");

    // AM or PM
    const meridiem = hours < 12 ? "AM" : "PM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Construct the formatted date and time string
    const formattedDateTime = `${day}/${month}/${year}, ${formattedHours}:${minutes}:${seconds} ${meridiem}`;

    return formattedDateTime;
  },

  appendParamsToURL: (obj, url) => {
    const params = new URLSearchParams();
    // Loop through the object properties
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key !== "page_no" && key !== "total_no_of_pages" && key !== "undefined") {
        if (Array.isArray(obj[key])) {
          params.append(key, JSON.stringify(obj[key]));
        } else {
          params.append(key, obj[key] ? obj[key]?.toString() : "");
        }
      }
    }
    const paramString = params.toString();
    const fullURL = `${url}?${paramString}`;
    return fullURL;
  },
  YearList: () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 25 }, (_, index) => {
      const year = currentYear - index;
      return { year_id: year, year_label: year };
    });
    return years;
  },
  months: () => {
    let months = [
      { month_id: 1, month_label: "January" },
      { month_id: 2, month_label: "February" },
      { month_id: 3, month_label: "March" },
      { month_id: 4, month_label: "April" },
      { month_id: 5, month_label: "May" },
      { month_id: 6, month_label: "June" },
      { month_id: 7, month_label: "July" },
      { month_id: 8, month_label: "August" },
      { month_id: 9, month_label: "September" },
      { month_id: 10, month_label: "October" },
      { month_id: 11, month_label: "November" },
      { month_id: 12, month_label: "December" },
    ];
    return months;
  },
  formatDateToIndianStandard: (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-IN", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  },
  toSentenceCase: (str) => {
    if (typeof str !== "string") {
      return "";
    }
    return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  },
  NotificationIcon: (notification_type) => {
    // Map notification_type to the corresponding icon
    let iconName;
    switch (notification_type) {
      case 1:
        iconName = "las la-chart-bar la-1-5x";
        break;
      case 2:
        iconName = "las la-envelope la-1-5x";
        break;
      case 3:
        iconName = "las la-bullhorn la-1-5x";
        break;
      case 4:
        iconName = "las la-briefcase la-1-5x";
        break;
      default:
        iconName = "las la-bell la-1-5x"; // A default icon in case of an unknown type
    }

    return iconName;
  },
  validateDateRange: (from_date, to_date, limit) => {
    const fromDate = new Date(from_date);
    const toDate = new Date(to_date);

    // Calculate the difference in days
    const dayDifference = (toDate - fromDate) / (1000 * 60 * 60 * 24);
    console.log(dayDifference, "dayDifference");
    if (dayDifference > limit) {
      console.error('Error: The "from_date" must be within 90 days of the "to_date".');
      return false;
    }

    return true;
  },
  filterBaseUrl: (apiUrl) => {
    const baseRegex = /^https?:\/\/[^\/]+/; // Matches the protocol and domain
    const baseMatch = apiUrl.match(baseRegex);

    if (baseMatch) {
      const baseUrl = baseMatch[0];
      const remainingPath = apiUrl.slice(baseUrl.length);
      return remainingPath;
    } else {
      // Handle cases where there's no base URL match
      console.error("Invalid API URL:", apiUrl);
      return ""; // Or return null, or throw an error, depending on your needs
    }
  },
  shuffleArray: (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  },
  formatAddress: (address, max) => {
    const maxLength = max;
    const pincode = 6;

    if (address.length > maxLength) {
      const visiblePart = address.substring(0, maxLength - pincode - 3);
      const lastPart = address.substring(address.length - pincode);
      return `${visiblePart}...${lastPart}`;
    }
    return address;
  },
};
