import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import WishSimpleCard from "../../components/WishSimpleCard";

export default function RankBadges({ ranks }) {
  return (
    <>
      <div className="col-12">
        <WishSimpleCard className="rounded-1 border-light" header={<h5>Rank Badges</h5>} changeBorder={false} cardBodyClassName="flex-none overflow-auto">
          <Swiper
            slidesPerView={6}
            modules={[Autoplay, Pagination, Navigation]}
            grabCursor={true}
            pagination={{
              clickable: false,
              dynamicBullets: true,
              
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
          >
            {(ranks ?? []).map((badge, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="text-center">
                    <img className="rounded-lg w-25" src={`/assets/app-assets/images/badges/${badge.title}.png`} alt={badge.title} />
                    <p style={{ fontSize: "12px" }}>{badge.title}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </WishSimpleCard>
      </div>
    </>
  );
}
