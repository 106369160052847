import useAPIs from "./useAPIs";

const CommonAPIs = () => {
  const { postData, getData } = useAPIs();

  const fetchLocationsFromPincode = (pincode, onSuccess, onFailure) => {
    getData(
      `/sales/v1/location/list/${pincode}`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        onFailure && onFailure(error);
      }
    );
  };

  const CheckEligStatus = (payload, onSuccess, onFailure) => {
    postData(
      "/sales/v1/order/new/eligstatus",
      payload,
      (data) => {
        console.log(data);
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        onFailure && onFailure(error);
      }
    );
  };

  const fetchProductsFromPincode = (pincode, onSuccess, onFailure) => {
    let city_id = "";
    let district_id = "";
    let state_id = "";
    let country_id = "";
    let product_category = "";
    let pv = "";
    let search_key = "";
    getData(
      `/sales/v1/products/vo?postcode_id=${pincode}&city_id=${city_id}&district_id=${district_id}&state_id=${state_id}&country_id=8${country_id}&product_category=${product_category}&pv=${pv}&search_key=${search_key}`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        onFailure && onFailure(error);
      }
    );
  };

  const fetchStoresWarehouses = (state_id, onSuccess, onFailure) => {
    let warehouse_type_id = "";
    let city_id = "";
    let district_id = "";
    let pincode = "";
    let country_id = "";
    getData(
      `/sales/v1/warehouse/list?warehouse_type_id=${warehouse_type_id}&postalcode_id=${pincode}&city_id=${city_id}&district_id=${district_id}&state_id=${state_id}&country_id=${country_id}`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        onFailure && onFailure(error);
      }
    );
  };

  const fetchUplineVotm = (payload, onSuccess, onFailure) => {
    postData(
      "/enrollment/fetch-votm-uplines-state",
      payload,
      (data) => {
        console.log(data);
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        onFailure && onFailure(error);
      }
    );
  };

  const fetchDeliveryModes = (pincode, onSuccess, onFailure) => {
    let city_id = "";
    let district_id = "";
    let state_id = "";
    let country_id = "";
    getData(
      `/sales/v1/master/deliverymodes?postcode_id=${pincode}&city_id=${city_id}&district_id=${district_id}&state_id=${state_id}&country_id=${country_id}`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        onFailure && onFailure(error);
      }
    );
  };

  const postOrderSummary = (payload, onSuccess, onError) => {
    postData(
      "/sales/v1/order-summary",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const postRubyOrderSummary = (payload, onSuccess, onError) => {
    postData(
      "/sales/v1/ruby-reward-order-summary",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  const fetchDistDetails = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/fetch-distributor-details",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  const searchDistributor = (payload, onSuccess, onFailure) => {
    postData(
      "/enrollment/fetch-geneology-distributor-id",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onFailure && onFailure(error);
      }
    );
  };
  const checkRubyRewards = (payload, onSuccess, onFailure) => {
    postData(
      "/sales/v1/check-eligible-ruby-reward",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onFailure && onFailure(error);
      }
    );
  };
  return {
    fetchLocationsFromPincode,
    CheckEligStatus,
    fetchProductsFromPincode,
    fetchStoresWarehouses,
    fetchUplineVotm,
    fetchDeliveryModes,
    postOrderSummary,
    fetchDistDetails,
    searchDistributor,
    checkRubyRewards,
    postRubyOrderSummary,
  };
};

export default CommonAPIs;
