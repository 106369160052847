import React from "react";
import NumberCounter from "../../../components/NumberCounter";
import WishSimpleCard from "../../../components/WishSimpleCard";

const QuickTile = ({ iconClass, bgClass, value, label }) => {
  return (
    <div className="col">
      <WishSimpleCard className={"equal-height"}>
        <div className="d-flex flex-direction-column align-items-center mt-md-0">
          <div className={`circle-box d-inline-block ${bgClass} mb-1`}>
            <i className={iconClass}></i>
          </div>
          <div className="text-center">
            <h1>
              <NumberCounter targetValue={value} />
            </h1>
            <label className="text-center quick-tile">{label}</label>
          </div>
        </div>
      </WishSimpleCard>
    </div>
  );
};
const QuickImageTile = ({ iconClass, bgClass, value, label }) => {
  return (
    <div className="col">
      <WishSimpleCard className={"equal-height"}>
        <div className="d-flex flex-direction-column align-items-center mt-md-0">
          <div className={`circle-box d-inline-block ${bgClass} mb-1`}>
            <i className={iconClass}></i>
          </div>
          <div className="text-center d-flex flex-direction-column ">
            <h2>PCM Member</h2>
            <label className="text-center quick-tile">{label}</label>
          </div>
        </div>
      </WishSimpleCard>
    </div>
  );
};

const QuickTilesDash = ({ dashboardData, activityDetails }) => {
  return (
    <div className="row">
      <QuickTile bgClass="bg-light-info" iconClass="las la-user" value={dashboardData.new_enrollees ?? 0} label="New Viva Retailers" />
      <QuickTile bgClass="bg-light-warning" iconClass="las la-shopping-bag" value={activityDetails.week_total_purchase_pv ?? 0} label="Retail PV" />
      <QuickTile bgClass="bg-light-warning" iconClass="las la-shopping-bag" value={activityDetails.week_total_sponsor_pv ?? 0} label="Personally sponsored VRs FO PV" />
      {activityDetails?.is_pcm_member ? (
        <QuickImageTile bgClass="bg-light-success" iconClass="las la-cart-arrow-down" value={activityDetails.current_pv_active ?? 0} label="Activity level" />
      ) : (
        <QuickTile bgClass="bg-light-success" iconClass="las la-cart-arrow-down" value={activityDetails.current_pv_active ?? 0} label="Activity level" />
      )}
      <QuickTile bgClass="bg-light-danger" iconClass="las la-calendar-week" value={activityDetails.next_activation_week ?? 0} label="Activation expires on" />
    </div>
  );
};

export default QuickTilesDash;
