import React, { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout";
import WishSimpleCard from "../../components/WishSimpleCard";
import { AppUtils } from "../../services/AppUtils";
import Pagination from "../../components/Pagination";
import VmailCompose from "./VmailCompose";
import VmailThreads from "./VmailThreads";
import useVmail from "../../services/useVmail";
import EmptyAnimation from "../../components/EmptyAnimation";
import LoadingNote from "../../components/LoadingNote";

export default function VmailNew() {
  const { postGeneral, getVmailMasters } = useVmail();
  const [masters, setMasters] = useState([]);
  const [mails, setMails] = useState([]);
  const [unreadCount, setUnreadCount] = useState(null);
  const [mailDetail, setMailDetail] = useState([]);
  const [selectedMail, setSelectedMail] = useState({});
  const [currentList, setCurrentList] = useState(true);
  const [isThreadView, setIsThreadView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [payload, setPayload] = useState({
    page_no: 1,
    total_no_of_pages: 0,
    subject_id: null,
    distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id,
  });

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "V-mail", linkTo: "/vmail" });

  useEffect(() => {
    getVmailMasters((data) => {
      setMasters(data);
      console.log(data);
    });
  }, []);

  useEffect(() => {
    getMails();
  }, [currentList, payload.is_closed, payload.subject_id]);

  const getMails = () => {
    setLoading(true);
    postGeneral(
      currentList ? 1 : 2,
      payload,
      (data) => {
        setMails(data);
        setLoading(false);
        if (currentList) {
          setUnreadCount(data?.unread_mail_count);
        }
        payload.total_no_of_pages = data?.total_no_of_pages ?? 0;
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleDetailClick = (item) => {
    setBtnLoading(true);
    let detailPayload = { parent_vmail_id: item.parent_vmail_id, distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id };
    postGeneral(
      3,
      detailPayload,
      (data) => {
        setSelectedMail(item);
        setMailDetail(data);
        setBtnLoading(false);
        setIsThreadView(true);
      },
      (error) => {
        setBtnLoading(false);
      }
    );
  };

  return (
    <PageLayout activeSideMenu="8" pageTitle="V-mail" header="V-mail" breadcrumbs={breadcrumbs}>
      <WishSimpleCard>
        <div>
          <div className="wraper bootstrap snippets bootdeys bootdey">
            <div className="row">
              <div className="col-md-3">
                <span
                  className="btn btn-success btn-block"
                  onClick={() => {
                    AppUtils.showDialog("dlgComposeMail");
                  }}
                >
                  Compose
                </span>
                <div className="panel panel-default p-0  m-t-20">
                  <div className="panel-body p-0">
                    <div className="list-group no-border mail-list">
                      <span
                        className={`list-group-item ${currentList ? "active" : null} clickable`}
                        onClick={(e) => {
                          setCurrentList(true);
                          setIsThreadView(false);
                          setPayload({ ...payload, page_no: 1, total_no_of_pages: 0, subject_id: null });
                        }}
                      >
                        <i className="la la-download mr-1"></i>Inbox <b>{unreadCount && `(${unreadCount})`}</b>
                      </span>
                      <span
                        className={`list-group-item ${!currentList ? "active" : null} clickable`}
                        onClick={(e) => {
                          setCurrentList(false);
                          setIsThreadView(false);
                          setPayload({ ...payload, page_no: 1, total_no_of_pages: 0, subject_id: null });
                        }}
                      >
                        <i className="la la-paper-plane-o mr-1"></i>Sent Mail
                      </span>
                    </div>
                  </div>
                </div>
                <h3 className="panel-title m-t-40">Filters</h3>
                <div className="panel panel-default p-0  m-t-20">
                  <div className="panel-body p-0">
                    <div className="list-group no-border">
                      <select
                        className="form-control list-group-item"
                        placeholder="Subject Id"
                        value={payload.subject_id}
                        onChange={(e) => {
                          setPayload({ ...payload, subject_id: parseInt(e.target.value), page_no: 1, total_no_of_pages: 0 });
                        }}
                      >
                        <option value={""} disabled selected hidden>
                          Subject ID
                        </option>
                        {masters?.map((item) => (
                          <option value={item.subject_id} key={item.subject_id}>
                            {item.subject_title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="list-group no-border">
                      <select
                        className="form-control list-group-item"
                        placeholder="Subject Id"
                        value={payload.is_closed}
                        onChange={(e) => {
                          setPayload({ ...payload, is_closed: e.target.value });
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Mail status
                        </option>
                        <option value={0}>Open</option>
                        <option value={1}>Closed</option>
                      </select>
                    </div> */}
                    <div className="list-group no-border mb-2">
                      <button
                        className="btn btn-outline-secondary mt-1"
                        onClick={(e) => {
                          e.preventDefault();
                          setPayload({ ...payload, page_no: 1, total_no_of_pages: 0, subject_id: "" });
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                {!isThreadView && (
                  <div className="panel panel-default">
                    <div className="panel-body">
                      {loading ? (
                        <>
                          <div className="card is-loading">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="content-1">
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : mails?.vmails?.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className={`table table-hover mails`}>
                              <colgroup>
                                {/* <col width="10%" /> */}
                                <col width="20%" />
                                <col width="60%" />
                                <col width="20%" />
                              </colgroup>
                              <thead className="thead-light">
                                <th>From</th>
                                <th>Subject</th>
                                <th></th>
                              </thead>
                              <tbody>
                                {mails?.vmails?.map((item) => (
                                  <tr key={item.vmail_id} onClick={(e) => handleDetailClick(item)}>
                                    {/* <td className="col-1">
                                    <label className="cr-styled">
                                      <input type="checkbox" />
                                      <i className="fa"></i>
                                    </label>
                                  </td> */}
                                    <td className="clickable">
                                      <p className={item.is_read ? null : "bold"}>{currentList ? item.from_name : item.to_name}</p>
                                    </td>
                                    <td className="clickable" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "1px" }}>
                                      <span className={item.is_read ? null : "bold"}>{item.title} </span>
                                      <span> - {item.body}</span>
                                    </td>
                                    <td className="text-right clickable">
                                      {item.has_attachment ? <i className="la la-paperclip mr-1"></i> : null}
                                      <span className={item.is_read ? null : "bold"}>{AppUtils.formatDate(item.create_time)}</span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <hr />
                          {mails?.total_no_of_pages && (
                            <Pagination
                              pageno={mails?.page_no}
                              onPageChange={(page) => {
                                payload.page_no = page;
                                getMails();
                              }}
                              totalPages={mails?.total_no_of_pages}
                            />
                          )}
                        </>
                      ) : (
                        <EmptyAnimation message={"No mails found"} />
                      )}
                    </div>
                  </div>
                )}
                {isThreadView && (
                  <VmailThreads
                    mailDetail={mailDetail}
                    formatDate={AppUtils.formatDate}
                    setIsThreadView={setIsThreadView}
                    getMails={getMails}
                    selectedMail={selectedMail}
                    setMailDetail={setMailDetail}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {btnLoading ? <LoadingNote /> : null}
      </WishSimpleCard>
      <VmailCompose subjects={masters} getMails={getMails} />
    </PageLayout>
  );
}
