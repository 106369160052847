import React from "react";
import Lottie from "react-lottie";
import animationData from "../assets/app-assets/images/lottie/31490-no-connection.json";

const NoNetworkFallbackUI = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Lottie options={defaultOptions} height={200} width={200} />
      <div style={{ marginLeft: "16px" }}>
        <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "8px", textAlign: "center" }}>Internet Connection Lost</h2>
        <p style={{ fontSize: "16px", textAlign: "center" }}>Please check your network connection and try again.</p>
      </div>
    </div>
  );
};

export default NoNetworkFallbackUI;
