import React from 'react'
import ReactDOM from 'react-dom/client'
// import * as Sentry from "@sentry/react";
import reportWebVitals from './reportWebVitals'
import App from './App'
import 'animate.css'
import './index.css'

// Determine the environment (development, production, staging)
const environment = process.env.NODE_ENV || 'development'

// Conditionally initialize Sentry based on the environment
// if (environment === "production") {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     environment: process.env.NODE_ENV,
//     release: `${process.env.APP_NAME}-${process.env.APP_VERSION}`,
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//       }),
//       new Sentry.Replay({
//         maskAllText: false,
//         blockAllMedia: false,
//       }),
//     ],
//   });
// }

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App />)

reportWebVitals()
