import React from "react";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import WishSimpleCard from "../../components/WishSimpleCard";
import { Link } from "react-router-dom";

export default function DownloadStatus({ AppdownloadedStats, data, className, width, height }) {
  const options = {
    // onClick: handleChartClick,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const plugin = {
    id: "custom_text",
    beforeDraw: (chart) => {
      const { ctx } = chart;
      ctx.save();
      ctx.font = "22px Inter";
      ctx.fillStyle = "grey";

      var line1 = AppdownloadedStats?.Downloaded ?? 0,
        line1X = Math.round((chart.width - ctx.measureText(line1).width) / 2),
        line1Y = chart.height / 2 - 25;
      ctx.fillText(line1, line1X, line1Y + 2);

      ctx.font = "14px Inter";
      var line2 = "Downloaded members",
        line2X = Math.round((chart.width - ctx.measureText(line2).width) / 2),
        line2Y = chart.height / 2;
      ctx.fillText(line2, line2X, line2Y + 2);

      ctx.font = "14px Inter";
      var line3 = "of " + (parseInt(AppdownloadedStats?.Downloaded) + parseInt(AppdownloadedStats?.NotDownloaded)),
        line3X = Math.round((chart.width - ctx.measureText(line3).width) / 2),
        line3Y = chart.height / 2 + 25;
      ctx.fillText(line3, line3X, line3Y + 2);
      ctx.restore();
    },
  };

  return (
    <WishSimpleCard className={className} changeBorder={false} header={<h5>App Download Stats as on {moment().format("DD-MMM-YY")}</h5>} cardBodyClassName="pt-0 wish-speedometer">
      <div className="text-center">
        <div
          style={{
            height: height,
            width: width,
          }}
        >
          {AppdownloadedStats ? (
            <Link to={"/mobileappteamcountreport"}>
              <Doughnut
                id="dgTeamMemberStatus"
                data={data}
                options={options}
                plugins={AppdownloadedStats && AppdownloadedStats?.Downloaded ? [plugin] : []}
                //ref={doughnutRef}
              />
            </Link>
          ) : (
            <div className="card skeleton-circle"></div>
          )}
        </div>
      </div>
      <div className="pt-2">
        <table width="100%">
          <thead>
            <tr>
              <th className="text-center">{AppdownloadedStats?.Downloaded}</th>
              <th className="text-center"> {AppdownloadedStats?.NotDownloaded}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center text-white" style={{ backgroundColor: "#87bcf1" }}>
                Downloaded
              </td>
              <td className="text-center text-white" style={{ backgroundColor: "#fbe892" }}>
                Not Downloaded
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </WishSimpleCard>
  );
}
