import useAPIs from "./useAPIs";

const distFromLocal = localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor")) : null;

const useAnnouncement = (distributorId) => {
  const { postData, getDataClone } = useAPIs();

  const fetchAnnouncements = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/v2/fetch-dist-announcements",
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  const downloadDocument = (url, onSuccess, onError) => {
    getDataClone(
      `/enrollment/download-signed-url?uid=${url}`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const downloadCoappKyc = (url, onSuccess, onError) => {
    getDataClone(
      `/enrollment/download-signed-url-coapp?uid=${url}`,
      (data) => {
        window.open(data.data, "_blank");
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const downloadAnnouncement = (url, onSuccess, onError) => {
    getDataClone(
      `/enrollment/download-signed-url-announcements?uid=${url}`,
      (data) => {
        window.open(data.data, "_blank");
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const downloadVmailAttachments = (url, onSuccess, onError) => {
    getDataClone(
      `/enrollment/download-signed-url-vmail?uid=${url}`,
      (data) => {
        // window.open(data.data, "_blank");
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  return {
    fetchAnnouncements,
    downloadDocument,
    downloadCoappKyc,
    downloadAnnouncement,
    downloadVmailAttachments,
  };
};

export default useAnnouncement;
