import { useEffect } from "react";
import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";

const useAnalysis = () => {
  const { apiError, postData } = useAPIs();

  useEffect(() => {
    console.log(apiError, "apiError");
    if (apiError) WishToaster({ toastType: "error", toastMessage: apiError });
  }, [apiError]);

  const callApiByType = (apiType, payload, onSuccess, onError) => {
    switch (apiType) {
      case "firstOrderTrend":
        return postData("/reports/first-order-trend", payload, onSuccess, onError);
      case "reOrderTrend":
        return postData("/reports/re-order-trend", payload, onSuccess, onError);
      case "rankTrend":
        return postData("/reports/rank-trend", payload, onSuccess, onError);
      case "volumeTrend":
        return postData("/reports/volume-trend", payload, onSuccess, onError);
      case "ordersTrend":
        return postData("/reports/order-trend", payload, onSuccess, onError);
      case "topRetailers":
        return postData("/reports/my-team-top-retail-leaders", payload, onSuccess, onError);
      default:
        throw new Error(`Invalid API type: ${apiType}`);
    }
  };

  const getTopleaders = (payload, onSuccess, onError) => {
    postData(
      `/reports/my-team-top-leaders`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };

  return {
    callApiByType,
    getTopleaders,
  };
};

export default useAnalysis;
