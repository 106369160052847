import React, { useEffect, useState } from "react";
import WishModal from "../../components/WishModal";
import WishUploadFiles from "../../components/WishUploadFiles";
import WishToaster from "../../components/WishToaster";
import { AppUtils } from "../../services/AppUtils";
import useVmail from "../../services/useVmail";

export default function VmailCompose({ subjects, getMails }) {
  const { postGeneralFormdata } = useVmail();
  const [message, setMessage] = useState({
    distributor_id: JSON.parse(localStorage.getItem("distributor"))?.distributor_id,
    subject_id: "",
    subject_title: "",
    body: "",
    has_attachment: 0,
    attachments: [],
  });
  const [loading, setLoading] = useState(false);
  const [resetFilesFlag, setResetFilesFlag] = useState(null);

  useEffect(() => {
    console.log(message);
  }, [message]);

  const sendMessage = (e) => {
    if (!message.subject_id) {
      WishToaster({
        toastMessage: "Subject is mandatory",
        toastType: "error",
      });
      return;
    }
    if (!message.body) {
      WishToaster({
        toastMessage: "Message is mandatory",
        toastType: "error",
      });
      return;
    }
    setLoading(true);
    let payload = new FormData();
    payload.append("distributor_id", message.distributor_id);
    payload.append("subject_id", message.subject_id);
    payload.append("subject_title", message.subject_title);
    payload.append("body", message.body);
    payload.append("has_attachment", message.attachments.length > 0 ? 1 : 0);
    for (let i = 0; i < message.attachments.length; i++) {
      payload.append("attachments", message.attachments[i] ? message.attachments[i] : null);
    }
    message.has_attachment = message.attachments.length > 0 ? 1 : 0;
    postGeneralFormdata(
      payload,
      1,
      (data) => {
        setLoading(false);
        getMails();
        AppUtils.hideDialog("dlgComposeMail");
        setMessage({ ...message, subject_id: "", subject_title: "", body: "", has_attachment: 0, attachments: [] });
        WishToaster({
          toastMessage: "Mail sent!",
          toastType: "success",
        });
        setResetFilesFlag(Math.random());
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <WishModal id="dlgComposeMail" title="New Mail" modalSize="modal-lg" noFooter={true}>
      <form>
        <div className="form-group row">
          <label htmlFor="ddTitle" className="col-sm-3 col-form-label">
            Subject
          </label>
          <div className="col-sm-9">
            <select
              id="ddTitle"
              name="ddTitle"
              className="custom-select"
              required="required"
              value={message.subject_id}
              onChange={(e) => {
                let index = e.nativeEvent.target.selectedIndex;
                setMessage({ ...message, subject_id: e.target.value, subject_title: e.nativeEvent.target[index].text });
              }}
            >
              <option value="">Choose...</option>
              {subjects &&
                subjects.map((subject, index) => {
                  return (
                    <option value={subject.subject_id} key={subject.subject_id}>
                      {subject.subject_title}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="txtMessage" className="col-sm-3 col-form-label">
            Message
          </label>
          <div className="col-sm-9">
            <textarea
              id="txtMessage"
              name="txtMessage"
              className="form-control"
              required="required"
              value={message.body}
              rows="10"
              onChange={(e) => {
                setMessage({ ...message, body: e.target.value });
              }}
            ></textarea>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="txtAttachment" className="col-sm-3 col-form-label">
            Attachments
          </label>
          <div className="col-sm-9">
            <WishUploadFiles
              hideUploadButton
              addButtonTitle="Add Attachment"
              resetFilesFlag={resetFilesFlag}
              onChange={(files) => {
                setMessage({ ...message, attachments: files });
              }}
            ></WishUploadFiles>
          </div>
        </div>
        <button type="button" className="btn btn-primary waves-effect waves-light ml-auto float-right d-flex" disabled={loading} onClick={sendMessage}>
          {loading ? (
            <>
              {/* <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div> */}
              <span>Sending...</span>
            </>
          ) : (
            <>
              <i className="la la-send"></i>
              <span style={{ marginLeft: "5px" }}>Send</span>
            </>
          )}
        </button>
      </form>
    </WishModal>
  );
}
