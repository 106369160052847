/* eslint-disable no-undef */
import toast, { Toaster } from "react-hot-toast";

const DisplayPositions = {
  leftTop: "toast-top-left",
  rightTop: "toast-top-right",
  leftBottom: "toast-bottom-left",
  rightBottom: "toast-bottom-right",
  topFull: "toast-top-full-width",
  bottomFull: "toast-bottom-full-width",
  topCenter: "toast-top-center",
  bottomCenter: "toast-bottom-center",
};

const ToastTypes = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
};

export default function WishToaster({ toastTitle, toastMessage, toastPosition, toastType, closeButton, show }) {
  switch (toastType) {
    case "error":
      toast.error(<span>{toastMessage}</span>);
      break;
    case "success":
      toast.success(<span>{toastMessage}</span>);
      break;
    case "congrats":
      // toast.success(<span>{toastMessage}</span>);
      toast(toastMessage, {
        icon: "👏",
      });
      break;
    default:
      toast.error(<div>{toastMessage}</div>);
      break;
  }
  // const showToast = function () {
  //   var objToast = toastr[toastType ?? "error"](toastMessage ?? "", toastTitle ?? "");
  //   toastr.options = {
  //     closeButton: closeButton ?? true,
  //     debug: false,
  //     newestOnTop: true,
  //     progressBar: true,
  //     positionClass: toastPosition ?? DisplayPositions.rightTop,
  //     preventDuplicates: true,
  //     onclick: null,
  //     showDuration: "100",
  //     hideDuration: "1000",
  //     timeOut: "3000",
  //     extendedTimeOut: "1000",
  //     showEasing: "swing",
  //     hideEasing: "linear",
  //     showMethod: "fadeIn",
  //     hideMethod: "fadeOut",
  //   };
  //   return objToast;
  // };
  // //   useEffect(() => {
  // //     if (show !== undefined) {
  // //       show.current = showToast;
  // //     }
  // //   });
  // return showToast();
}
