import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'animate.css'

import EmptyBox from '../components/EmptyBox'
import PageLayout from '../components/PageLayout'
import WishFlexBox from '../components/WishFlexBox'
import WishSimpleCard from '../components/WishSimpleCard'
import WishToaster from '../components/WishToaster'
import useDashboard from '../services/useDashboard'
import useMasters from '../services/useMasters'
import SkeletonLoader from '../components/SkeletonLoader'
import NumberCounter from '../components/NumberCounter'
// import NotificationContext from "../contexts/NotificationContext";
import useELearning from '../services/useE-Learning'

const SwalAlert = withReactContent(Swal)

export default function Dashboard() {
	// const requestPermission = useContext(NotificationContext);
	const navigate = useNavigate()

	const { updateDistributor } = useMasters()
	const { error, getActivityDetails, getDistributorDetails, getThaiDetails } =
		useDashboard(localStorage.getItem('dist_id'))
	const { getTourdeThaiQuestions, tourDeThaiQuizTracker } = useELearning()

	const [payload, setPayload] = useState({
		language_id: 6,
		distributor_id: localStorage.getItem('dist_id'),
	})
	const [quizTaken, setQuizTaken] = useState(false)
	const [activityDetails, setActivityDetails] = useState({})
	const [distributorDetails, setDistributorDetails] = useState({})
	const [report, setReport] = useState({})
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (error) {
			WishToaster({ toastMessage: error })
		}
	}, [error])

	useEffect(() => {
		// requestPermission();
		getActivityDetails(data => {
			setActivityDetails(data)
		})
		getThaiDetails(data => {
			setReport(data)
			setLoading(false)
		})
		getDistributorDetails(data => {
			setDistributorDetails(data)
			updateDistributor(data)
		})
		getTourdeThaiQuestions(payload, data => {
			setQuizTaken(data?.is_quiz_attended)
			if (!data?.is_quiz_attended) {
				tourDeThaiQuizTracker(
					{
						distributor_id: localStorage.getItem('dist_id'),
						is_notif_shown: 1,
					},
					data => {}
				)
				SwalAlert.fire({
					iconHtml:
						'<img src="./assets/app-assets/images/ico/android-chrome-192x192.png" alt="Quiz Icon" style="width: 50px; height: 50px;">',
					title: 'Tour de Thai Quiz and Training Video',
					text: 'There is a Tour de Thai quiz and training video available. We urge you to take it.',
					showCancelButton: true,
					confirmButtonText: `Let's go`,
					cancelButtonText: 'Later',
				}).then(result => {
					if (result.isConfirmed) {
						navigate('/tourdethaiquiz')
					}
				})
			}
		})
	}, [])

	const distSkeleton = () => {
		return (
			<>
				<div className='skeleton-image'></div>
				<div className='skeleton-text'>
					<div className='skeleton-line'></div>
					<div className='skeleton-line'></div>
					<div className='skeleton-line'></div>
				</div>
				<div className='col-sm-12'>
					<div className='card is-loading'>
						<div className='row'>
							<div className='col-sm-12 m-0 p-0'>
								<div className='content-1'>
									<p></p>
									<p></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	const carouselData = [
		{
			id: 1,
			imageSrc: '../assets/app-assets/images/carousel/ipulse.jpg',
			altText: 'First slide',
		},
		{
			id: 2,
			imageSrc: '../assets/app-assets/images/carousel/ICOFFEE.jpg',
			altText: 'Second slide',
		},
		{
			id: 3,
			imageSrc: '../assets/app-assets/images/carousel/ICARE.jpg',
			altText: 'Third slide',
		},
		{
			id: 4,
			imageSrc: '../assets/app-assets/images/carousel/iglow.jpg',
			altText: 'Fourth slide',
		},
		{
			id: 5,
			imageSrc: '../assets/app-assets/images/carousel/ISLIM.jpg',
			altText: 'Fifth slide',
		},
	]

	const AppDownloadButton = ({
		href,
		title,
		platform,
		imgSrc,
		imgAlt,
		btnText,
		label,
	}) => {
		return (
			<a
				className={`btn-downlaod btn-${platform}`}
				href={href}
				target='_blank'
				title={title}
				rel='noopener noreferrer'
			>
				{btnText}
				<span className='btn-label'>{label}</span>
				<span
					className='btn-icon'
					style={{ backgroundImage: `url(${imgSrc})` }}
					aria-label={imgAlt}
				></span>
			</a>
		)
	}

	const RenderPage = function () {
		return (
			<PageLayout activeSideMenu='1' pageTitle='Dashboard'>
				<div className='row'>
					<div className='col-sm-12'>
						<WishSimpleCard cardBodyClassName='pb-0'>
							<div className='row'>
								<div className='col-12'>
									<h3>Welcome back {distributorDetails?.name}!</h3>
								</div>
								<Swiper
									slidesPerView={1}
									modules={[Autoplay, Pagination, Navigation]}
									grabCursor={true}
									pagination={{
										clickable: false,
										dynamicBullets: true,
									}}
									autoplay={{
										delay: 4000,
										disableOnInteraction: false,
									}}
								>
									{(carouselData ?? []).map((item, index) => {
										return (
											<SwiperSlide key={index}>
												<img
													className='rounded-lg w-100 h-100'
													src={item.imageSrc}
													alt={item.altText}
												/>
											</SwiperSlide>
										)
									})}
								</Swiper>
							</div>
						</WishSimpleCard>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6'>
						<WishSimpleCard className={'equal-height'}>
							<h5 className='text-center'>My Info</h5>
							{Object.keys(distributorDetails).length > 0 ? (
								<div className='text-center mt-2'>
									<img
										src={`../../assets/app-assets/images/badges/${distributorDetails?.distributor_rank?.rank_title}.png`}
										alt=''
										style={{ width: '50px' }}
									/>
									<EmptyBox height='10px' />
									<p style={{ lineHeight: '1.0' }}>
										<label className='d-block'>
											{distributorDetails?.distributor_id}
										</label>
										<h5>{distributorDetails?.name}</h5>
										<label>
											{distributorDetails?.distributor_rank?.rank_title}
										</label>
									</p>
									<div className='row'>
										<div className='col-4'>
											<label>Paid as rank</label>
										</div>
										<div className='col-8'>
											<h6 className='m-0 text-right'>
												{activityDetails.current_paid_rank_title}{' '}
											</h6>
										</div>
									</div>
									<div className='row'>
										<div className='col-4'>
											<label>Profile Completion</label>
										</div>
										<div className='col-8'>
											<Link to={'/myprofile'}>
												<div
													className='progress progress-analysis'
													role='progressbar'
													aria-label='Progress bar'
												>
													<div
														className='progress-bar progress-bar-analysis'
														style={{
															width: `${
																distributorDetails?.profile_completion_percent
																	? distributorDetails?.profile_completion_percent
																	: 0
															}%`,
														}}
													>
														<strong>
															{distributorDetails?.profile_completion_percent
																? distributorDetails?.profile_completion_percent
																: 0}{' '}
															%
														</strong>
													</div>
												</div>
											</Link>
										</div>
									</div>
								</div>
							) : (
								distSkeleton()
							)}
						</WishSimpleCard>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6'>
						<WishSimpleCard className={'equal-height'}>
							<h5 className='text-center'>Activity Status</h5>
							{Object.keys(activityDetails).length > 0 ? (
								<>
									<WishFlexBox className='rounded-lg border-light bg-light-1 mt-2'>
										<div className='col-12 text-center'>
											<label className='fs-2 d-block'>
												{activityDetails.current_pv_active}
											</label>
											<label>Activation PV</label>
										</div>
									</WishFlexBox>
									<div className='row mt-3'>
										<div className='col-6'>
											<label className='text-info'>
												Next activation week:{' '}
											</label>
										</div>
										<div className='col-6'>
											<h5 className='text-right'>
												{activityDetails.next_activation_week}
											</h5>
										</div>
									</div>
									<label className='font-weight-bold text-danger col-12 text-center'>
										{activityDetails.comment}
									</label>
								</>
							) : (
								<SkeletonLoader rows={4} />
							)}
						</WishSimpleCard>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6'>
						<WishSimpleCard className={'equal-height'}>
							<div className='col-12'>
								<h5 className='text-center'>Need support?</h5>
								<p className='text-center mb-2'>Contact your sponsor!</p>
							</div>
							{Object.keys(distributorDetails).length > 0 ? (
								<div className='col-12 text-center'>
									<img
										src={`../../assets/app-assets/images/badges/${distributorDetails?.sponsor_profile?.sponsor_rank?.rank_title}.png`}
										alt=''
										style={{ width: '50px', marginBottom: '5px' }}
									/>
									<h6>{distributorDetails?.sponsor_profile?.sponsor_name}</h6>
									<p style={{ lineHeight: '1.0' }}>
										<label className='d-block'>
											{
												distributorDetails?.sponsor_profile
													?.sponsor_distributor_id
											}
										</label>
										<small>
											{
												distributorDetails?.sponsor_profile?.sponsor_rank
													?.rank_title
											}
										</small>
									</p>
									<img
										src='../../assets/app-assets/images/elements/telephone-call.png'
										alt=''
										style={{
											width: '40px',
											marginRight: '15px',
											cursor: 'pointer',
										}}
										onClick={e => {
											navigator.clipboard.writeText(
												distributorDetails?.sponsor_profile?.sponsor_phone
											)
											SwalAlert.fire({
												icon: 'success',
												title: 'Contact copied',
												text: `Sponser mobile no ${distributorDetails?.sponsor_profile?.sponsor_phone} copied to clipboard.`,
											})
										}}
									/>
									<img
										src='../../assets/app-assets/images/elements/mail.png'
										alt=''
										style={{ width: '40px', cursor: 'pointer' }}
										onClick={e => {
											navigator.clipboard.writeText(
												distributorDetails?.sponsor_profile?.sponsor_email
											)
											SwalAlert.fire({
												icon: 'success',
												title: 'E-mail copied',
												text: `Sponser e-mail ${distributorDetails?.sponsor_profile?.sponsor_email} copied to clipboard.`,
											})
										}}
									/>
									<div className='mt-2 mb-1'>
										<h6 className='mb-1'>
											Great News!!! The Indusviva mobile app is here!
										</h6>
										<a
											className='btn-downlaod btn-google custom-heartBeat'
											href='https://play.google.com/store/apps/details?id=com.indusviva.app.indusviva&listing=poster'
											target='_blank'
											rel='noreferrer'
											title='Google Play'
										>
											Google Play
										</a>
										<a
											className='btn-downlaod btn-apple custom-heartBeat'
											href='https://apps.apple.com/app/indusviva-app/id6474152992?ppid=c85689e0-40d5-4298-bdb3-c572f3a25e0b'
											target='_blank'
											rel='noreferrer'
											title='App Store'
										>
											App Store
										</a>
									</div>
								</div>
							) : (
								distSkeleton()
							)}
						</WishSimpleCard>
					</div>
					{Object.keys(report).length > 0 ? (
						<>
							<div className='col-lg-5 col-md-6 col-sm-6'>
								<WishSimpleCard className='equal-height'>
									<h5 className='text-center d-flex justify-content-center align-items-center'>
										Tour De Thai - Eligibility Criteria
										<span className='badge badge-primary text-small ml-1 custom-tada'>
											New
										</span>
									</h5>
									{!loading ? (
										<div className='mt-3'>
											<div className='row'>
												<div className='col-5'>
													<label>Achieve Star rank</label>
												</div>
												<div className='col-7'>
													<p className='text-right font-weight-bold'>
														{report.stardata === 'Done' && (
															<img
																alt='checked-gif'
																src='assets/app-assets/images/elements/wired-lineal-37-approve-checked-simple.gif'
																height='20px'
																style={{ marginRight: '5px' }}
															/>
														)}
														{report.stardata}
													</p>
												</div>
											</div>
											<div className='row'>
												<div className='col-5'>
													<label>Achieve Star-250 rank</label>
												</div>
												<div className='col-7'>
													<p className='text-right font-weight-bold'>
														{report.star250 === 'Done' && (
															<img
																alt='checked-gif'
																src='assets/app-assets/images/elements/wired-lineal-37-approve-checked-simple.gif'
																height='20px'
																style={{ marginRight: '5px' }}
															/>
														)}
														{report.star250}
													</p>
												</div>
											</div>
											<div className='row'>
												<div className='col-5'>
													<label>Achieve Star-500 rank</label>
												</div>
												<div className='col-7'>
													<p className='text-right font-weight-bold'>
														{report.star500 === 'Done' && (
															<img
																alt='checked-gif'
																src='assets/app-assets/images/elements/wired-lineal-37-approve-checked-simple.gif'
																height='20px'
																style={{ marginRight: '5px' }}
															/>
														)}
														{report.star500}
													</p>
												</div>
											</div>
											<div className='row'>
												<div className='col-5'>
													<label>
														{report?.flag
															? 'Qualify 5 people for the tour'
															: 'Sponsor 5 stars'}
													</label>
												</div>
												<div className='col-7'>
													<p className='text-right font-weight-bold'>
														{report.sponserdata}
													</p>
												</div>
											</div>
											<div className='row'>
												<label className='font-weight-bold text-primary col-12 text-center'>
													{report.week_left}
												</label>
											</div>
										</div>
									) : (
										<SkeletonLoader rows={5} />
									)}
								</WishSimpleCard>
							</div>
							<div className='col-lg-7 col-md-6 col-sm-6'>
								<WishSimpleCard className={'equal-height'}>
									<h5 className='text-center d-flex justify-content-center align-items-center'>
										Watch List
										<span className='badge badge-primary text-small ml-1 custom-tada'>
											New
										</span>
									</h5>
									<h6 className='text-center mt-3'>
										Dear VBO, To become eligible for the tour,
										{report?.flag
											? 'Qualify 5 people for the tour'
											: 'maintain 5 personally sponsored active stars every week till the tour is conducted.'}
									</h6>
									{!report?.flag && (
										<h6 className='text-center'>Previous week counts below</h6>
									)}
									{!report?.flag && (
										<div className='row'>
											{report.weeks_data &&
												Object.entries(report.weeks_data).map(
													([keyName, value], i) => (
														<div className='col d-flex justify-content-center align-items-center'>
															<div className='d-flex flex-direction-column align-items-center mt-md-0'>
																<div className='text-center'>
																	<h1>
																		<NumberCounter targetValue={value} />
																	</h1>
																	<label className='text-center quick-tile'>
																		Week {keyName}
																	</label>
																</div>
															</div>
														</div>
													)
												)}
										</div>
									)}
									{/* <h6>{report?.last_3_weeks_data}</h6> */}
									{!loading ? (
										<div style={{ maxHeight: '300px', overflowY: 'auto' }}>
											<div className='table-responsive'>
												<table className='table table-striped mt-2'>
													<thead>
														<tr>
															<th>Distributor Name</th>
															<th>Join Week</th>
															<th>Activity Status</th>
															<th>Star Achieved Week</th>
															<th>Current Achieved Rank</th>
															<th>Next Activation Week</th>
														</tr>
													</thead>
													<tbody>
														{report?.watchlist?.map((item, index) => (
															<tr key={index}>
																<td>
																	{item.distributor_name}({item.distributor_id})
																</td>
																<td>{item.join_week_id}</td>
																<td
																	className={
																		item.activity_status === 'Active'
																			? ''
																			: 'text-danger'
																	}
																>
																	{item.activity_status}
																</td>
																<td>{item.star_achieved_week}</td>
																<td>{item.current_achieved_rank}</td>
																<td>{item.reset_week_id}</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									) : (
										<SkeletonLoader rows={5} />
									)}
								</WishSimpleCard>
							</div>
						</>
					) : null}
				</div>
			</PageLayout>
		)
	}

	return <RenderPage />
}
