import { useEffect, useState } from "react";
import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";

const useDashboard = (distributor) => {
  const { apiError, postData, getData, downloadFile } = useAPIs();
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  useEffect(() => {
    getStudentAccess();
  }, []);

  const getActivityDetails = (onSuccess) => {
    postData("/reports/dashboard-activity-details", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getThaiDetails = (onSuccess) => {
    postData("/reports/star_500_report", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getDistributorDetails = (onSuccess) => {
    postData("/enrollment/fetch-distributor-details", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getDashboardVolumes = (onSuccess) => {
    postData("/reports/dashboard-group-volumes", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getDashboardWeeklyPerformance = (onSuccess) => {
    postData("/reports/dashboard-weekly-performance", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };

  const getAppDownloadedStats = (onSuccess) => {
    postData("/reports/fetch-mobileapp-team-count", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };

  const getMasterTablesForWeek = (onSuccess) => {
    postData("/reports/master-tables-for-week-commission-report", {}, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getMasterTablesForMonth = (onSuccess) => {
    getData("/reports/master-tables-for-month", {}, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const get4WeekCommission = (onSuccess) => {
    postData("/reports/fetch-four-week-commission-report-of-a-distributor", { distributor_id: distributor }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getWeeklyCommission = (week_id, onSuccess) => {
    postData("/reports/weekly-commission-report-of-a-distributor", { distributor_id: distributor, week_id: week_id }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getWeeklyDetailedCommission = (week_id, onSuccess) => {
    postData("/reports/weekly-detailed-commission-report-of-a-distributor", { distributor_id: distributor, week_id: week_id }, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const downloadWeeklyCommission = (week_id, onSuccess, onError) => {
    downloadFile(
      `/reports/download-weekly-commission-report-of-a-distributor?distributor_id=${distributor}&week_id=${week_id}`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getMyDeductionList = (payload, onSuccess) => {
    postData(
      "/reports/my-deductions",
      {
        page_no: payload.page_no,
        total_no_of_pages: payload.total_no_of_pages,
        distributor_id: distributor,
      },
      (data) => {
        onSuccess && onSuccess(data);
      }
    );
  };
  const getMyDeductionListForWeek = (page_no, week_id, no_of_pages, onSuccess) => {
    postData(
      "/reports/my-deductions-for-given-week",
      {
        page_no: page_no,
        week_id: week_id,
        no_of_pages: no_of_pages,
        distributor_id: distributor,
      },
      (data) => {
        onSuccess && onSuccess(data);
      }
    );
  };
  const getMyAdjustmentList = (payload, onSuccess) => {
    postData(
      "/reports/my-adjustments",
      {
        page_no: payload.page_no,
        no_of_pages: payload.total_no_of_pages,
        distributor_id: distributor,
      },
      (data) => {
        onSuccess && onSuccess(data);
      }
    );
  };
  const getMyAdjustmentListForWeek = (page_no, week_id, no_of_pages, onSuccess) => {
    postData(
      "/reports/my-adjustments-for-given-week",
      {
        page_no: page_no,
        week_id: week_id,
        no_of_pages: no_of_pages,
        distributor_id: distributor,
      },
      (data) => {
        onSuccess && onSuccess(data);
      }
    );
  };
  const getCoAppBonusReport = (payload, onSuccess) => {
    postData(
      "/reports/my-co-applicant-bonus-report",
      {
        page_no: payload.page_no,
        no_of_pages: payload.total_no_of_pages,
        distributor_id: distributor,
      },
      (data) => {
        onSuccess && onSuccess(data);
      }
    );
  };
  const getCoAppBonusDetailedReport = (payload, royalty_rank_id, onSuccess) => {
    postData(
      "/reports/my-co-applicant-bonus-detailed-report",
      {
        page_no: payload.page_no,
        total_no_of_pages: payload.total_no_of_pages,
        distributor_id: distributor,
        royalty_rank_id: royalty_rank_id,
      },
      (data) => {
        onSuccess && onSuccess(data);
      }
    );
  };
  const getOrderReportMasters = (onSuccess, onError) => {
    postData(
      "/reports/master-tables-my-order-report",
      {},
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getMyOrderReport = (payload, onSuccess) => {
    postData("/reports/fetch-my-order-report", payload, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getMyVOTMOrderReport = (payload, onSuccess) => {
    postData("/reports/fetch-my-votm-order-report", payload, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getMyStoreLinkOrderReport = (payload, onSuccess) => {
    postData("/reports/fetch-my-storelink-order-report", payload, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const newDistributorReport = (payload, onSuccess, onError) => {
    postData(
      `/reports/profile-analysis-new-distributor-report`,
      payload,
      (data, response) => {
        onSuccess(data, response);
      },
      (error) => {
        WishToaster({
          toastTitle: "Error",
          toastMessage: error && "Something went wrong!",
        });
        onError && onError(error);
      }
    );
  };

  function getStudentAccess() {
    const payload = {
      distributor_id: JSON.parse(localStorage.getItem("dist_id")),
    };

    postData(
      `/enrollment/genelogy-access-for-students`,
      payload,
      (data) => {
        localStorage.setItem("student_access", JSON.stringify(data));
      },
      (error) => {
        WishToaster({
          toastTitle: "Error",
          toastMessage: error && "Something went wrong!",
        });
      }
    );
  }

  return {
    error,
    getActivityDetails,
    getDistributorDetails,
    getDashboardVolumes,
    getDashboardWeeklyPerformance,
    downloadWeeklyCommission,
    getMasterTablesForMonth,
    getMasterTablesForWeek,
    get4WeekCommission,
    getWeeklyCommission,
    getWeeklyDetailedCommission,
    getMyDeductionList,
    getMyDeductionListForWeek,
    getMyAdjustmentList,
    getMyAdjustmentListForWeek,
    getCoAppBonusReport,
    getCoAppBonusDetailedReport,
    getOrderReportMasters,
    getMyOrderReport,
    getMyVOTMOrderReport,
    getMyStoreLinkOrderReport,
    newDistributorReport,
    getThaiDetails,
    getAppDownloadedStats,
    getStudentAccess,
  };
};

export default useDashboard;
