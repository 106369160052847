import React, { useState } from "react";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import { AUTHENTICATION_MODES } from "../../services/Constants";

const SignInForm = ({ credentials, setCredentials, errorMessage, isLoading, DoLogin, setMode, setErrorMessage }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="card-body">
      <WishSingleLineText
        placeholder="Direct Seller Id"
        icon="las la-user"
        initialValue={credentials.user_name}
        onChange={(value) => {
          setCredentials({ ...credentials, user_name: value });
        }}
      />
      <WishSingleLineText
        placeholder="Your password"
        icon="las la-lock"
        initialValue={credentials.password}
        type={showPassword ? "text" : "password"}
        onChange={(value) => {
          setCredentials({ ...credentials, password: value });
        }}
      />
      <div className="form-group row pb-2">
        <div className="col-md-6 col-12 text-center text-sm-left">
          <input
            type="checkbox"
            name="showpassword"
            id="showpassword"
            onChange={(e) => {
              setShowPassword(e.target.checked);
            }}
          />
          <label className="ml-1" htmlFor="showpassword">
            Show Password
          </label>
        </div>
        <div className="col-md-6 col-12 float-sm-left text-center text-sm-right">
          <button
            className="card-link link-dotted text-primary"
            onClick={() => {
              setErrorMessage("");
              setCredentials({ user_name: "", password: "" });
              setMode(AUTHENTICATION_MODES.forgotPassword);
            }}
          >
            Forgot Password?
          </button>
        </div>
      </div>
      <div className="form-group text-center">
        <p className="text-danger">{errorMessage}</p>
      </div>
      <div className="form-group text-center">
        <button
          type="button"
          disabled={isLoading}
          onClick={() => {
            DoLogin();
          }}
          className={"btn btn-block text-uppercase " + (isLoading ? " btn-secondary" : " btn-success ")}
        >
          {isLoading ? <>Signing in...</> : <>Sign In</>}
        </button>
      </div>
    </div>
  );
};

export default SignInForm;
