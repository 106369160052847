import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ReactApexChart from "react-apexcharts";
import PageLayout from "../../components/PageLayout";
import pageConfig from "../../data/config.json";
import WishSimpleCard from "../../components/WishSimpleCard";
import useDashboard from "../../services/useDashboard";
import TeamMemberStatus from "../Genealogy/TeamMemberStatus";
import DownloadStatus from "../Genealogy/DownloadStatus";
import useAnalysis from "../../services/useAnalysis";
import { OrdersTrendOptions } from "../Reports/TrendsConfig";
import SkeletonLoader from "../../components/SkeletonLoader";
import { GroupVolumeSkeleton } from "./components/GroupVolumeSkeleton";
import { DistProfileBox } from "./components/DistProfileBox";
import QuickTilesDash from "./components/QuickTilesDash";
import { GroupVolume } from "./components/GroupVolume";
import EmptyAnimation from "../../components/EmptyAnimation";
import animation from "../../assets/app-assets/images/lottie/NoDataAvailable.json";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Analysis() {
  const distributor = JSON.parse(localStorage.getItem("distributor"));
  const ranks = JSON.parse(localStorage.getItem("ranks"));
  const studentAccess = JSON.parse(localStorage.getItem("student_access"));

  const distributor_rank_id = distributor.distributor_rank.rank_id;

  const { callApiByType, getTopleaders } = useAnalysis();
  const { error, getDistributorDetails, getActivityDetails, getDashboardVolumes, getDashboardWeeklyPerformance, getAppDownloadedStats } = useDashboard(distributor?.distributor_id);
  const [activityDetails, setActivityDetails] = useState({});
  const [distributorDetails, setDistributorDetails] = useState({});
  const [dashboardVolumes, setDashboardVolumes] = useState([]);
  const [dashboardWeeklyPerformance, setDashboardWeeklyPerformance] = useState({});
  const [appDownloadedStats, setAppDownloadedStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [options, setOptions] = useState(OrdersTrendOptions);
  const [payload, setPayload] = useState({
    distributor_id: distributor?.distributor_id,
  });
  const [leadersPayload, setLeadersPayload] = useState({
    page_no: 1,
    total_no_of_pages: 0,
    distributor_id: distributor?.distributor_id,
    position_id: null,
    from_week_id: null,
    to_week_id: null,
    top_count: null,
  });
  const [topLeaders, setTopLeaders] = useState([]);
  const [topRetailers, setTopRetailers] = useState([]);
  const [maxGV, setMaxGV] = useState(Number.MAX_VALUE);

  const chartData = {
    labels: ["Active Members", "Inactive Members", "Other Members"],
    datasets: [
      {
        data: [
          dashboardWeeklyPerformance?.memberCounts?.Active ? dashboardWeeklyPerformance?.memberCounts?.Active : 100,
          dashboardWeeklyPerformance?.memberCounts?.Inactive ? dashboardWeeklyPerformance?.memberCounts?.Inactive : 0,
          dashboardWeeklyPerformance?.memberCounts?.Others ? dashboardWeeklyPerformance?.memberCounts?.Others : 0,
        ],
        backgroundColor: ["#87bcf1", "#fbe892", "#f893b0"],
        borderColor: ["#87bcf1", "#fbe892", "#f893b0"],
        borderWidth: 0,
        cutout: "85%",
      },
    ],
  };

  const chartDataAppDownload = {
    labels: ["Downloaded Members", "Not Downloaded Members"],
    datasets: [
      {
        data: [appDownloadedStats?.Downloaded ? appDownloadedStats?.Downloaded : 100, appDownloadedStats?.NotDownloaded ? appDownloadedStats?.NotDownloaded : 0],
        backgroundColor: ["#87bcf1", "#fbe892"],
        borderColor: ["#87bcf1", "#fbe892"],
        borderWidth: 0,
        cutout: "85%",
      },
    ],
  };

  useEffect(() => {
    if (distributor?.distributor_rank?.rank_id > 1 || studentAccess?.comment !== 0) {
      getActivityDetails((data) => {
        setActivityDetails(data);
      });
      getDistributorDetails((data) => {
        setDistributorDetails(data);
      });
      getDashboardVolumes((data) => {
        setDashboardVolumes(data);
      });
      getDashboardWeeklyPerformance((data) => {
        console.log(data);
        setDashboardWeeklyPerformance(data);
      });
      getAppDownloadedStats((data) => {
        console.log(data);
        setAppDownloadedStats(data);
      });
      getLists();
    }
  }, []);

  useEffect(() => {
    setMaxGV(Math.max(...dashboardVolumes.map((item) => Math.max(...item.gv))));
  }, [dashboardVolumes]);

  const getLists = () => {
    setLoading(true);
    callApiByType(
      "ordersTrend",
      payload,
      (data, response) => {
        setReport(data.data);
        setOptions({ ...options, xaxis: { ...options.xaxis, categories: data.categories } });
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
    getTopleaders(
      leadersPayload,
      (data, response) => {
        setTopLeaders(data.report);
      },
      (error) => {}
    );
    callApiByType(
      "topRetailers",
      leadersPayload,
      (data, response) => {
        setTopRetailers(data.report);
      },
      (error) => {}
    );
  };

  const renderChart = function () {
    return (
      <>
        {loading ? (
          <SkeletonLoader rows={6} />
        ) : (
          <>
            <div id="chart" className="sales-chart-wrapper">
              <ReactApexChart options={options} series={report} type={options.chart.type} height={"100%"} width={"100%"} />
            </div>
          </>
        )}
      </>
    );
  };

  const getCurrentWeek = () => {
    let currentweek = dashboardVolumes.filter((item) => item?.is_current_week)[0];
    return currentweek?.week_id ? `- Week ${currentweek?.week_id - 1}` : "";
  };

  return (
    <PageLayout {...pageConfig.analysisdashboard}>
      {distributor?.distributor_rank?.rank_id > 1 || studentAccess?.comment !== 0 ? (
        <>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <DistProfileBox distributorDetails={distributorDetails} activityDetails={activityDetails} />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <QuickTilesDash dashboardData={dashboardWeeklyPerformance} activityDetails={activityDetails} />
              {/* {activityDetails.comment && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body" style={{ padding: "5px" }}></div>
                </div>
              </div>
            </div>
          )} */}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-8">
              <WishSimpleCard header={<h4>Group Volume Details - Current week</h4>}>
                <div className="row">
                  {dashboardVolumes.length > 0 ? (
                    dashboardVolumes
                      .filter((item) => item?.is_current_week) // Filter items with is_current_week true
                      .map((item, itemIndex) => (
                        <div key={itemIndex} className="col-sm-12 col-md-12 col-lg-12">
                          <GroupVolume maxGV={maxGV} weekData={item} />
                        </div>
                      ))
                  ) : (
                    <GroupVolumeSkeleton />
                  )}
                </div>
              </WishSimpleCard>
            </div>
            <div className="col-sm-12 col-md-5 col-lg-4">
              <TeamMemberStatus data={chartData} distributorMemberStats={dashboardWeeklyPerformance?.memberCounts} className={"border-light"} width={"250px"} height={"250px"} />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <WishSimpleCard header={<h4>Group Volume Details - Previous weeks</h4>}>
                <div className="row">
                  {dashboardVolumes.length > 0
                    ? dashboardVolumes
                        .filter((item) => !item?.is_current_week) // Filter items with is_current_week false
                        .map((item, itemIndex) => (
                          <div key={itemIndex} className="col-sm-12 col-md-6 col-lg-4 mb-1">
                            <GroupVolume maxGV={maxGV} weekData={item} />
                          </div>
                        ))
                    : Array.from({ length: 3 }, (item, index) => (
                        <div className="col-sm-12 col-md-6 col-lg-4">
                          <div className="gv-tile">
                            <GroupVolumeSkeleton key={index} />
                          </div>
                        </div>
                      ))}
                </div>
              </WishSimpleCard>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <WishSimpleCard header={<h4>Sales Analysis</h4>}>{renderChart()}</WishSimpleCard>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <WishSimpleCard header={<h4>Your Top Performers {getCurrentWeek()}</h4>} className={"max-height"}>
                <div className="top-leaders-wrapper">
                  {topLeaders?.length > 0 ? (
                    topLeaders?.map((item) => (
                      <div className="d-flex">
                        <img src={ranks.find((rank) => rank.id === item.current_achieved_rank_id)?.preview_url} alt="" style={{ width: "50px", height: "50px" }} />
                        <div className="ml-1 mb-1">
                          <h6 className="mb-0">
                            {item?.distributor_name} ({item?.distributor_id})
                          </h6>
                          <small>{item?.current_achieved_rank_title}</small>
                          <small className="d-block">Gross Revenue : {item?.total_income_sum}</small>
                        </div>
                      </div>
                    ))
                  ) : (
                    <SkeletonLoader rows={10} />
                  )}
                </div>
              </WishSimpleCard>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <WishSimpleCard header={<h4>Your Top Retailers {getCurrentWeek()}</h4>} className={"max-height"}>
                <div className="top-leaders-wrapper">
                  {topRetailers?.length > 0 ? (
                    topRetailers?.map((item) => (
                      <div className="d-flex">
                        <img src={ranks.find((rank) => rank.id === item.current_achieved_rank_id)?.preview_url} alt="" style={{ width: "50px", height: "50px" }} />
                        <div className="ml-1 mb-1">
                          <h6 className="mb-0">
                            {item?.distributor_name} ({item?.distributor_id})
                          </h6>
                          <small>{item?.current_achieved_rank_title}</small>
                          <small className="d-block">Weekly Purchase PV : {item?.weekly_purchase_pv}</small>
                        </div>
                      </div>
                    ))
                  ) : (
                    <SkeletonLoader rows={10} />
                  )}
                </div>
              </WishSimpleCard>
            </div>
            {appDownloadedStats?.Downloaded && distributor_rank_id > 9 && distributor_rank_id < 16 ? (
              <div className="col-sm-12 col-md-5 col-lg-4 p-2">
                <DownloadStatus data={chartDataAppDownload} AppdownloadedStats={appDownloadedStats} className={"border-light"} width={"250px"} height={"250px"} />
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <div className="col-12">
          <EmptyAnimation message={"No data to display. Please come back when you're a star distributor or above"} lottieAnimation={animation} />
        </div>
      )}
    </PageLayout>
  );
}
