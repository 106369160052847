import { useEffect, useState, useCallback } from "react";
import useAPIs from "./useAPIs";

const useELearning = (distributor) => {
  const { apiError, postData } = useAPIs();
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(apiError);
  }, [distributor, apiError]);

  const handlePostRequest = useCallback(
    (endpoint, payload, onSuccess, onError) => {
      postData(
        endpoint,
        payload,
        (data) => {
          onSuccess && onSuccess(data);
        },
        (error) => {
          onError && onError(error);
        }
      );
    },
    [postData]
  );

  const getTourdeThaiQuestions = useCallback(
    (payload, onSuccess, onError) => {
      handlePostRequest("/reports/tour-de-thai-quiz-questions", payload, onSuccess, onError);
    },
    [handlePostRequest]
  );

  const submitTourdeThaiQuiz = useCallback(
    (payload, onSuccess, onError) => {
      handlePostRequest("/reports/tour-de-thai-quiz-submit", payload, onSuccess, onError);
    },
    [handlePostRequest]
  );

  const tourDeThaiQuizTracker = useCallback(
    (payload, onSuccess, onError) => {
      handlePostRequest("/reports/tour-de-thai-quiz-tracker", payload, onSuccess, onError);
    },
    [handlePostRequest]
  );

  return {
    error,
    getTourdeThaiQuestions,
    submitTourdeThaiQuiz,
    tourDeThaiQuizTracker,
  };
};

export default useELearning;
