import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";

const useVmail = () => {
  const { postData, getData, postFormData } = useAPIs();

  const urlList = [
    { key: 1, url: "/enrollment/vo/vmail-inbox" },
    { key: 2, url: "/enrollment/vo/sent-vmail" },
    { key: 3, url: "/enrollment/vo-vmail-threads" },
  ];
  const formDataURLList = [
    { key: 1, url: "/enrollment/vo-new-mail" },
    { key: 2, url: "/enrollment/vo-reply-mail" },
  ];
  const postGeneral = (URLid, payload, onSuccess, onError) => {
    let URLtoCall = urlList.find((item) => item.key === URLid);
    postData(
      URLtoCall?.url,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const getVmailMasters = (onSuccess, onError) => {
    getData(
      `/enrollment/vo-fetch-vmail-subject-list`,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const postGeneralFormdata = (payload, URLid, onSuccess, onError) => {
    let URLtoCall = formDataURLList.find((item) => item.key === URLid);
    postFormData(
      URLtoCall?.url,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  return {
    getVmailMasters,
    postGeneral,
    postGeneralFormdata,
  };
};

export default useVmail;
