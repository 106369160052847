/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import WishFlexBox from "./WishFlexBox";
import useAuthentication from "../services/useAuthentication";
import { NOTIFICATIONS_LIMIT } from "../services/Constants";
import { useEffect } from "react";
import useLocalStorage from "react-use-localstorage";
import useMasters from "../services/useMasters";
import { useState } from "react";
import moment from "moment";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "../services/AppUtils";

export default function PageTopMenu({ className = "", pageTitle = "", onClick }) {
  const { loggedInUser, getNotifications } = useMasters();
  const navigateTo = useNavigate();
  const { error, logout } = useAuthentication();
  const [distributor, setDistributor] = useLocalStorage("distributor", "");
  const [notifications, setNotifications] = useState([]);
  const [payload, setPayload] = useState({ page_no: 1, total_no_of_pages: 0 });

  useEffect(() => {
    if (loggedInUser) {
      getNotifications(
        payload,
        (data) => {
          const dataArray = data?.report || [];
          // console.log(dataArray);
          if (dataArray.length <= 5) {
            setNotifications(dataArray);
          } else {
            setNotifications(dataArray.slice(0, NOTIFICATIONS_LIMIT));
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (error) {
      WishToaster({ toastMessage: error });
    }
  }, [error]);

  const isNoficationValid = (notification) => {
    const dateFrom = new Date(notification?.valid_from);
    const dateTo = new Date(notification?.valid_upto);
    const today = new Date();

    return dateFrom <= today && today <= dateTo;
  };

  const logoutUser = () => {
    if (distributor === "") {
      navigateTo("/signin");
    } else {
      const distributorDetails = JSON.parse(distributor);
      if (distributorDetails.distributor_id === "") {
        navigateTo("/signin");
      } else {
        const credentials = {
          user_name: distributorDetails.distributor_id,
          isReadyToAuthenticate: true,
        };

        logout(
          credentials,
          (logoutResponse) => {
            console.log(logoutResponse);
            setDistributor("");
            navigateTo("/signin");
          },
          (err) => {
            localStorage.clear();
            navigateTo("/signin");
          }
        );
      }
    }
  };

  const RenderNotifications = function () {
    return (
      <div className="arrow_box_right">
        <ul className="list-group list-group-flush">
          {notifications?.length > 0 ? (
            notifications?.map((notification) => {
              if (isNoficationValid(notification)) {
                return (
                  <li className="list-group-item py-0 px-2 trackable" key={notification.notification_id}>
                    <WishFlexBox justifyContent="start">
                      <i className={AppUtils.NotificationIcon(notification.notification_type_id)}></i>
                      <p className="pl-1 pt-1" style={{ lineHeight: "1.3" }}>
                        <strong className="d-block">{notification.body}</strong>
                        <small className="d-block">{moment(notification.valid_from).startOf("day").fromNow()}</small>
                      </p>
                    </WishFlexBox>
                  </li>
                );
              }

              return <></>;
            })
          ) : (
            <h6 className="text-center mt-2 mb-2">No notifications found</h6>
          )}
        </ul>

        {notifications?.length > 0 && (
          <div
            className="text-center dropdown-item clickable bg-light pt-1"
            onClick={() => {
              navigateTo("/notifications");
            }}
          >
            <label className="clickable underlined">
              <strong>View all</strong>
            </label>
          </div>
        )}
      </div>
    );
  };

  return (
    <nav className={"header-navbar navbar-expand-md navbar navbar-without-dd-arrow navbar-gradient header-sticky" + (className ?? " ")}>
      <div className="navbar-wrapper">
        <div className="navbar-container content">
          <div className="collapse navbar-collapse show" id="navbar-mobile">
            <ul className="nav navbar-nav mr-auto float-left">
              <li className="nav-item" onClick={onClick}>
                <a className="nav-link nav-menu-main hidden-xs">
                  <i className="las la-bars"></i>
                </a>
              </li>
            </ul>
            <ul className="nav navbar-nav float-right">
              <li className="dropdown dropdown-language nav-item">
                <a className="nav-link">{pageTitle}</a>
              </li>
              <li className="dropdown dropdown-user nav-item">
                <a className="dropdown-toggle nav-link dropdown-user-link" data-toggle="dropdown">
                  <span className="avatar avatar-online">
                    <img style={{ width: "30px" }} src="../assets/app-assets/images/portrait/small/Bell.svg" alt="avatar" />
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right notifications-drawer" id="mnuNotifications">
                  {<RenderNotifications />}
                </div>
              </li>
              <li className="dropdown dropdown-user nav-item">
                <a className="dropdown-toggle nav-link dropdown-user-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="avatar avatar-online">
                    <img src="../assets/app-assets/images/portrait/PlaceHolder.png" alt="avatar" />
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right" id="topMenuDropDown">
                  <div className="arrow_box_right">
                    <Link className="dropdown-item d-flex align-items-center" to="/myprofile">
                      <span className="avatar avatar-online">
                        <img src="./assets/app-assets/images/portrait/PlaceHolder.png" alt="avatar" />
                      </span>
                      <span className="user-name text-bold-700 ml-1 truncate-text">
                        ({loggedInUser?.distributor_id}){loggedInUser?.name}
                      </span>
                    </Link>
                    <div className="pl-2">
                      <small>{loggedInUser?.distributor_rank?.rank_title}</small>
                    </div>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/myprofile">
                      <i className="las la-user"></i> My Profile
                    </Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/settings">
                      <i className="las la-cog"></i> Settings
                    </Link>
                    <a className="dropdown-item clickable" onClick={logoutUser}>
                      <i className="las la-sign-out-alt"></i> Signout / Switch Account
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
