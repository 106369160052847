import { useState } from "react";
import { Link } from "react-router-dom";

export const GroupVolume = ({ weekData, maxGV }) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const organizationNames = [
    "Organization 1",
    "Organization 2",
    "Organization 3",
    "Organization 4",
    "Organization 5",
    "Organization 6",
    "Organization 7",
    "Organization 8",
    "Organization 9",
    "Organization 10",
  ];

  const toggleShowAllRows = () => {
    setShowAllRows(!showAllRows);
  };

  return (
    <div className={`gv-tile ${weekData?.is_current_week ? "current-week-gv" : ""}`}>
      <div className="row mt-1 mb-2">
        <div className={`col-sm-6 col-md-6  ${weekData?.is_current_week ? "col-lg-3" : "col-lg-6"} d-flex align-items-center flex-direction-column mb-1`}>
          <h5 className="text-center m-0">{weekData?.week_id}</h5>
          <label className="text-center">Week</label>
        </div>
        <div className={`col-sm-6 col-md-6  ${weekData?.is_current_week ? "col-lg-3" : "col-lg-6"} d-flex align-items-center flex-direction-column mb-1`}>
          <Link to={!weekData?.is_current_week ? "/commissionreports" : ""} state={{ weekData: weekData }} style={{ color: "#6b6f80", fontWeight: "300" }}>
            <h5 className="text-center m-0">{weekData?.total_income}</h5>
            <label className="text-center">Aggregate Revenue</label>
          </Link>
        </div>
        <div className={`col-sm-6 col-md-6  ${weekData?.is_current_week ? "col-lg-3" : "col-lg-6"} d-flex align-items-center flex-direction-column mb-1`}>
          <h5 className="text-center m-0">{weekData?.current_achieved_rank_title}</h5>
          <label className="text-center">Rank</label>
        </div>
        <div className={`col-sm-6 col-md-6  ${weekData?.is_current_week ? "col-lg-3" : "col-lg-6"} d-flex align-items-center flex-direction-column mb-1`}>
          <h5 className="text-center m-0">{weekData?.current_paid_rank_title}</h5>
          <label className="text-center">Paid as rank</label>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table table-borderless">
            <tbody>
              {weekData?.gv?.map(
                (value, index) =>
                  // Show only the first 2 rows or all rows when "Read More" is clicked
                  (showAllRows || index < 2) && (
                    <tr key={index}>
                      <td className="p-0 text-center org-column-analysis">
                        <label>{organizationNames[index]}</label>
                      </td>
                      <td className="p-0">
                        <div className="progress progress-analysis" role="progressbar" aria-label="Progress bar">
                          <div
                            className="progress-bar progress-bar-analysis"
                            style={{
                              width: `${(weekData?.gv[index] / maxGV) * 100}%`,
                            }}
                          >
                            <strong>{weekData?.gv[index]} GV</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
      {weekData?.berthCount > 2 && (
        <div className="row">
          <div className="col-12 text-right">
            <button onClick={toggleShowAllRows}>{showAllRows ? "View Less" : "View More"}</button>
          </div>
        </div>
      )}
    </div>
  );
};
