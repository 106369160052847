import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import PageLayout from '../../components/PageLayout'
import MySVG from '../../assets/app-assets/images/svg/wave.svg'
import useAuthentication from '../../services/useAuthentication'
import {
	AUTHENTICATION_MODES,
	EMPTY_CREDENTIALS,
} from '../../services/Constants'
import SignInForm from './SignInForm'
import ForgotPassword from './ForgotPassword'
import VerifyOTP from './VerifyOTP'

const SignIn = ({ setisAutheticated }) => {
	const [mode, setMode] = useState(AUTHENTICATION_MODES.sign)
	const [errorMessage, setErrorMessage] = useState('')
	const [credentials, setCredentials] = useState({
		user_name: '',
		password: '',
	})
	const [isLoading, setIsLoading] = useState(false)
	const { error, login, forgotPassword, processing } = useAuthentication()
	const [forgotPasswordResponse, setForgotPasswordResponse] = useState(null)

	useEffect(() => {
		if (error) {
			setErrorMessage(error)
			setCredentials({ user_name: '', password: '' })
			setIsLoading(false)
		}
	}, [error])

	useEffect(() => {
		setIsLoading(processing)
	}, [processing])

	const DoLogin = () => {
		if (
			credentials.user_name.trim() !== '' &&
			credentials.password.trim() !== ''
		) {
			setErrorMessage(null)
			login(credentials, loginResponse => {
				localStorage.setItem('dist_id', loginResponse.user_name)
				setisAutheticated(true)
				navigate('/')
			})
		} else {
			setErrorMessage(EMPTY_CREDENTIALS)
		}
	}

	const DoChangePassword = () => {
		if (credentials.user_name.trim() !== '') {
			setErrorMessage(null)
			setIsLoading(false)
			forgotPassword(
				{
					user_name: credentials.user_name,
					user_type: 1,
				},
				response => {
					setForgotPasswordResponse(response)
					setMode(AUTHENTICATION_MODES.forgotPasswordSuccess)
				}
			)
		} else {
			setErrorMessage(EMPTY_CREDENTIALS)
		}
	}

	const navigate = useNavigate()

	const renderFormBasedOnMode = () => {
		switch (mode) {
			case AUTHENTICATION_MODES.sign:
				return (
					<SignInForm
						credentials={credentials}
						setCredentials={setCredentials}
						errorMessage={errorMessage}
						isLoading={isLoading}
						DoLogin={DoLogin}
						setMode={setMode}
						setErrorMessage={setErrorMessage}
					/>
				)

			case AUTHENTICATION_MODES.forgotPassword:
				return (
					<ForgotPassword
						credentials={credentials}
						errorMessage={errorMessage}
						isLoading={isLoading}
						DoChangePassword={DoChangePassword}
						setMode={setMode}
						setCredentials={setCredentials}
						setErrorMessage={setErrorMessage}
					/>
				)

			case AUTHENTICATION_MODES.forgotPasswordSuccess:
				return (
					<VerifyOTP
						forgotPasswordResponse={forgotPasswordResponse}
						setMode={setMode}
						credentials={credentials}
					/>
				)

			default:
				return null
		}
	}

	return (
		<section
			className='flexbox-container'
			style={{
				backgroundImage: `url(${MySVG})`,
				backgroundSize: 'cover',
				height: '100vh',
				display: 'flex',
			}}
		>
			<div className='col-12 d-flex align-items-center justify-content-center'>
				<div className='col-lg-4 col-md-6 col-sm-12 p-0'>
					<div
						className='card border-grey border-lighten-3 px-1 py-1 m-0 page-fade-in'
						style={{ borderRadius: '30px', backgroundColor: '#ffffffd4' }}
					>
						<div
							className='card-header border-0'
							style={{ backgroundColor: 'rgb(255 255 255 / 0%)' }}
						>
							<div className='text-center mb-1'>
								<img
									src='./assets/app-assets/images/ico/android-chrome-192x192.png'
									width='100px'
									alt='Indusviva Health Sciences'
								/>
							</div>
							<div className='text-center'>
								<h1 className='badge badge-primary badge-lg text-uppercase'>
									Indusviva Virtual Office
								</h1>
								{/* <h6 className="text-uppercase"></h6> */}
							</div>
						</div>
						<div className='card-content'>{renderFormBasedOnMode()}</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SignIn
